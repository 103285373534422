import React, { useState } from 'react';
import { Wrapper, FormContent } from './styles'
import bannerThanks from '../../assets/banner-thanks.png';

export function Contact() {

  return (
    <Wrapper>
      <FormContent>
        <img src={bannerThanks} alt='Banner Obrigado' />
      </FormContent>
    </Wrapper>
  )
}
