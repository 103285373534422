import React, { useEffect, useState } from 'react';
import { Wrapper, Footer, Content, Title, Sections, StepsOverview, Next,
  Step01, Step02, Step03, Step04, Step05, Step06, Step07, TiposDeProduto, StepTitle,
  TiposDeFormulas, TipoContent, TipoCapsula, DivisaoCapsula, FormualarioEnvio, FormSubmit
} from './styles'
import { IoRocketOutline } from 'react-icons/io5'
import { BiCapsule } from 'react-icons/bi'
import { FcOk } from 'react-icons/fc'
import { SiAtom } from 'react-icons/si'
import { FaCircle } from 'react-icons/fa'
import { CgEditContrast } from 'react-icons/cg'
import { FiChevronsRight, FiChevronsLeft, FiChevronRight, FiCheckCircle, FiRotateCw, FiHexagon, FiCheckSquare } from 'react-icons/fi'
import logo2 from '../../assets/footer-logo.png';
import encapsuladosFoto from '../../assets/sua-marca-encaps.png';
import soluveisFoto from '../../assets/sua-marca-creatina.png';
import liquidoFoto from '../../assets/sua-marca-liquido.png';
import gummyFoto from '../../assets/sua-marca-gummy.png';

import capsulaAmarela from '../../assets/amarelo.png';
import capsulaBranca from '../../assets/branca.png';
import capsulaPreta from '../../assets/preta.png';
import capsulaVermelha from '../../assets/vermelha.png';
import capsulaVerde from '../../assets/verde.png';
import capsulaVerdeEscuro from '../../assets/verde-escuro.png';
import capsulaAzul from '../../assets/azul.png';
import capsulaAzulTurquesa from '../../assets/azul-turquesa.png';
import capsulaTransp from '../../assets/transparente.png';
import capsulaLaranja from '../../assets/laranja.png';
import capsulaVinho from '../../assets/vinho.png';
import capsulaRoxo from '../../assets/roxo.png';
import capsulaRosa from '../../assets/rosa.png';

import tampaLisaBranca from '../../assets/tampa-lisa-branca.png';
import tampaLisaPreta from '../../assets/tampa-lisa-preta.png';
import tampaFlipCristal from '../../assets/flip-top-cristal.png';
import tampaFlipAzul01 from '../../assets/flip-top-azul01.png';
import tampaFlipAzul02 from '../../assets/flip-top-azul02.png';
import tampaFlipAmarela01 from '../../assets/flip-top-amarelo01.png';
import tampaFlipAmarela02 from '../../assets/flip-top-amarelo02.png';
import tampaFlipAmarela03 from '../../assets/flip-top-amarelo03.png';
import tampaFlipDourada from '../../assets/filp-top-dourada.png';
import tampaFlipLaranja01 from '../../assets/flip-top-laranja01.png';
import tampaFlipLaranja02 from '../../assets/flip-top-laranja02.png';
import tampaFlipLaranja03 from '../../assets/flip-top-laranja03.png';
import tampaFlipPrata from '../../assets/flip-top-prata.png';
import tampaFlipPreta from '../../assets/flip-top-preta.png';
import tampaFlipRosa01 from '../../assets/flip-top-rosa01.png';
import tampaFlipRosa02 from '../../assets/flip-top-rosa02.png';
import tampaFlipVerde01 from '../../assets/flip-top-verde01.png';
import tampaFlipVerde02 from '../../assets/flip-top-verde02.png';
import tampaFlipVerde03 from '../../assets/flip-top-verde03.png';
import tampaFlipVermelho from '../../assets/flip-top-vermelho.png';
import tampaFlipVioleta from '../../assets/flip-top-violeta.png';
import tampaFlipBranca from '../../assets/flip-top-branca.png';

import frascoEncapsTransparente from '../../assets/frasco-transparente.png'
import frascoEncapsAmarelo from '../../assets/frasco-amarelo.png'
import frascoEncapsAmbar from '../../assets/frasco-ambar.png'
import frascoEncapsAzul1 from '../../assets/frasco-azul.png'
import frascoEncapsAzul2 from '../../assets/frasco-azul2.png'
import frascoEncapsAzul3 from '../../assets/frasco-azul3.png'
import frascoEncapsBranco from '../../assets/frasco-branco.png'
import frascoEncapsFume from '../../assets/frasco-fume.png'
import frascoEncapsLaranja from '../../assets/frasco-laranja.png'
import frascoEncapsPreto from '../../assets/frasco-preto.png'
import frascoEncapsRoxo from '../../assets/frasco-roxo.png'
import frascoEncapsVerdeEscuro from '../../assets/frasco-verde-escuro.png'
import frascoEncapsVerde2 from '../../assets/frasco-verde2.png'
import frascoEncapsVermelho from '../../assets/frasco-vermelho.png'
import frascoEncapsVioleta from '../../assets/frasco-violeta.png'

import gummyVermelho from '../../assets/gummy-vermelho.png'
import gummyAzul from '../../assets/gummy-azul.png'
import gummyAmarelo from '../../assets/gummy-amarelo.png'
import gummyLaranja from '../../assets/gummy-laranja.png'
import gummyVerde from '../../assets/gummy-verde.png'
import gummyRosa from '../../assets/gummy-rosa.png'
import gummyTransparente from '../../assets/gummy-transparente.png'
import gummyRoxo from '../../assets/gummy-roxo.png'

import potePretoSoluvel from '../../assets/pote-preto.png'
import poteBrancoSoluvel from '../../assets/pote-branco.png'

import frascoPretoLiquido from '../../assets/pote-liquido-tampa-preta.png'
import frascoBrancoLiquido from '../../assets/pote-liquido-tampa-branca.png'
import { FormGroup } from '../Contact/styles';

export function Products() {
  const [currentStep, setCurrentStep] = useState(0)
  const [tiposDeProduto, setTiposDeProduto] = useState('')
  const [quantidade, setQuantidade] = useState(0)
  const [capsulasQtde, setCapsulasQtde] = useState(60)
  const [capsulaImagem, setCapsulaImagem] = useState(capsulaTransp)
  const [gummyImagem, setGummyImagem] = useState(gummyVermelho)
  const [formulaSelecionada, setFormulaSelecionada] = useState('')
  const [capsulaSelecionada, setCapsulaSelecionada] = useState('Transparente')
  const [gummySelecionado, setGummySelecionado] = useState('Sabor Morango')
  const [tampaImagem, setTampaImagem] = useState(tampaFlipCristal)
  const [frascoImagem, setFrascoImagem] = useState(frascoEncapsTransparente)
  const [tampaSelecionada, setTampaSelecionada] = useState('Tampa Flip Top Cristal')
  const [frascoSelecionado, setFrascoSelecionado] = useState('Pote PET Cristal')
  const [soluvelSelecionado, setSoluvelSelecionado] = useState('Pote PEAD Preto')
  const [soluvelImagem, setSoluvelImagem] = useState(potePretoSoluvel)
  const [liquidoSelecionado, setLiquidoSelecionado] = useState('Frasco Âmbar c/ Tampa Preta')
  const [liquidoImagem, setLiquidoImagem] = useState(frascoPretoLiquido)

  let selecoesFormulario = "Orçamento\n\n" +
    "Tipo de Produto: " + tiposDeProduto + "\n" +
    "Fórmula: " + formulaSelecionada + "\n";

  if (tiposDeProduto === 'liquido') selecoesFormulario = selecoesFormulario + "Frasco Líquido: " + liquidoSelecionado + "\n"
  // if (tiposDeProduto === 'gummy') selecoesFormulario = selecoesFormulario + "Cor da Goma: " + gummySelecionado + "\n"
  if (tiposDeProduto === 'encapsulado') selecoesFormulario = selecoesFormulario + "Cor da Capsula: " + capsulaSelecionada + "\n"

  if (tiposDeProduto === 'encapsulado') {
    selecoesFormulario = selecoesFormulario + "Cor da Tampa: " + tampaSelecionada + "\n";
    selecoesFormulario = selecoesFormulario + "Cor do Pote: " + frascoSelecionado + "\n";
  }
  
  if (tiposDeProduto === 'soluvel') selecoesFormulario = selecoesFormulario + "Pote Solúvel: " + soluvelSelecionado + "\n";

  selecoesFormulario =  selecoesFormulario + "Quantidade Selecionada: " + quantidade + "\n" +
  "Quantidade de Capsulas, Ml ou Gramas: " + capsulasQtde + "\n";

  function handleUpdateTampaOption(option: string) {
    switch(option) {
      case 'Tampa Lisa Branca':
        setTampaImagem(tampaLisaBranca);
        setTampaSelecionada('Tampa Lisa Branca');
        break;
      case 'Tampa Lisa Preta':
        setTampaImagem(tampaLisaPreta);
        setTampaSelecionada('Tampa Lisa Preta');
        break;
      case 'Tampa Flip Top Cristal':
        setTampaImagem(tampaFlipCristal);
        setTampaSelecionada('Tampa Flip Top Cristal');
        break;
      case 'Tampa Flip Top Azul/1':
        setTampaImagem(tampaFlipAzul01);
        setTampaSelecionada('Tampa Flip Top Azul/1');
        break;
      case 'Tampa Flip Top Azul/2':
        setTampaImagem(tampaFlipAzul02);
        setTampaSelecionada('Tampa Flip Top Azul/2');
        break;
      case 'Tampa Flip Top Amarela/1':
        setTampaImagem(tampaFlipAmarela01);
        setTampaSelecionada('Tampa Flip Top Amarela/1');
        break;
      case 'Tampa Flip Top Amarela/2':
        setTampaImagem(tampaFlipAmarela02);
        setTampaSelecionada('Tampa Flip Top Amarela/2');
        break;
      case 'Tampa Flip Top Branca':
        setTampaImagem(tampaFlipBranca);
        setTampaSelecionada('Tampa Flip Top Branca');
        break;
      case 'Tampa Flip Top Amarela/3':
        setTampaImagem(tampaFlipAmarela03);
        setTampaSelecionada('Tampa Flip Top Amarela/3');
        break;
      case 'Tampa Flip Top Dourada':
        setTampaImagem(tampaFlipDourada);
        setTampaSelecionada('Tampa Flip Top Dourada');
        break;
      case 'Tampa Flip Top Laranja/1':
        setTampaImagem(tampaFlipLaranja01);
        setTampaSelecionada('Tampa Flip Top Laranja/1');
        break;
      case 'Tampa Flip Top Laranja/2':
        setTampaImagem(tampaFlipLaranja02);
        setTampaSelecionada('Tampa Flip Top Laranja/2');
        break;
      case 'Tampa Flip Top Laranja/3':
        setTampaImagem(tampaFlipLaranja03);
        setTampaSelecionada('Tampa Flip Top Laranja/3');
        break;
      case 'Tampa Flip Top Prata':
        setTampaImagem(tampaFlipPrata);
        setTampaSelecionada('Tampa Flip Top Prata');
        break;
      case 'Tampa Flip Top Preta':
        setTampaImagem(tampaFlipPreta);
        setTampaSelecionada('Tampa Flip Top Preta');
        break;
      case 'Tampa Flip Top Rosa/1':
        setTampaImagem(tampaFlipRosa01);
        setTampaSelecionada('Tampa Flip Top Rosa/1');
        break;
      case 'Tampa Flip Top Rosa/2':
        setTampaImagem(tampaFlipRosa02);
        setTampaSelecionada('Tampa Flip Top Rosa/2');
        break;
      case 'Tampa Flip Top Verde/1':
        setTampaImagem(tampaFlipVerde01);
        setTampaSelecionada('Tampa Flip Top Verde/1');
        break;
      case 'Tampa Flip Top Verde/2':
        setTampaImagem(tampaFlipVerde02);
        setTampaSelecionada('Tampa Flip Top Verde/2');
        break;
      case 'Tampa Flip Top Verde/3':
        setTampaImagem(tampaFlipVerde03);
        setTampaSelecionada('Tampa Flip Top Verde/3');
        break;
      case 'Tampa Flip Top Vermelha':
        setTampaImagem(tampaFlipVermelho);
        setTampaSelecionada('Tampa Flip Top Vermelha');
        break;
      case 'Tampa Flip Top Violeta':
        setTampaImagem(tampaFlipVioleta);
        setTampaSelecionada('Tampa Flip Top Violeta');
        break;
        case 'Cor Personalizada':
          setTampaImagem(tampaFlipCristal);
          setTampaSelecionada('Cor Personalizada');
          break;
    }
  }

  function handleUpdateCapsulaOption(option: string) {
    switch(option) {
      case 'Transparente':
        setCapsulaSelecionada('Transparente')
        setCapsulaImagem(capsulaTransp);
        break;
      case 'Cor Personalizada':
        setCapsulaSelecionada('Cor Personalizada')
        setCapsulaImagem(capsulaTransp);
        break;
      case 'Branca':
        setCapsulaSelecionada('Branca')
        setCapsulaImagem(capsulaBranca);
        break;
      case 'Preta':
        setCapsulaSelecionada('Preta')
        setCapsulaImagem(capsulaPreta);
        break;
      case 'Azul':
        setCapsulaSelecionada('Azul')
        setCapsulaImagem(capsulaAzul);
        break;
      case 'Azul-Turquesa':
        setCapsulaSelecionada('Azul-Turquesa')
        setCapsulaImagem(capsulaAzulTurquesa);
        break;
      case 'Vermelha':
        setCapsulaSelecionada('Vermelha')
        setCapsulaImagem(capsulaVermelha);
        break;
      case 'Verde':
        setCapsulaSelecionada('Verde')
        setCapsulaImagem(capsulaVerde);
        break;
      case 'Verde Escuro':
        setCapsulaSelecionada('Verde Escuro')
        setCapsulaImagem(capsulaVerdeEscuro);
        break;
      case 'Laranja':
        setCapsulaSelecionada('Laranja')
        setCapsulaImagem(capsulaLaranja);
        break;
      case 'Amarela':
        setCapsulaSelecionada('Amarela')
        setCapsulaImagem(capsulaAmarela);
        break;
      case 'Vinho':
        setCapsulaSelecionada('Vinho')
        setCapsulaImagem(capsulaVinho);
        break;
      case 'Rosa':
        setCapsulaSelecionada('Rosa')
        setCapsulaImagem(capsulaRosa);
        break;
      case 'Roxa':
        setCapsulaSelecionada('Roxa')
        setCapsulaImagem(capsulaRoxo);
        break;
    }
  }
  
  function handlerClearAndReturn(stepToReturn: number) {
    switch (stepToReturn) {
      case 0:
        setCurrentStep(0)
        setTiposDeProduto('')
        setFormulaSelecionada('')
        setCapsulaSelecionada('Transparente')
        break;
      case 1:
        setCurrentStep(1)
        setFormulaSelecionada('')
        setCapsulaSelecionada('Transparente')
        break;
      case 2:
        setCurrentStep(2)
        setCapsulaSelecionada('Transparente')
        break;
      case 3:
        setCurrentStep(3)
        setTampaSelecionada('Tampa Flip Top Cristal')
        setTampaImagem(tampaLisaBranca)
        setFrascoSelecionado('Pote PET Cristal')
        setFrascoImagem(frascoEncapsTransparente)
        break;
      case 4:
        if (tiposDeProduto === 'soluvel' || tiposDeProduto === 'liquido') {
          setCurrentStep(3)
          setQuantidade(0)
          setCapsulasQtde(60)
        } else {
          setCurrentStep(4)
          setQuantidade(0)
          setCapsulasQtde(60)
        }
        break;
    }
  }

  function handlerUpdateFrascoOption(option: string) {
    switch(option) {
      case 'Pote PET Cristal':
        setFrascoImagem(frascoEncapsTransparente);
        setFrascoSelecionado('Pote PET Cristal');
        break;
      case 'Pote PET Branco':
        setFrascoImagem(frascoEncapsBranco);
        setFrascoSelecionado('Pote PET Branco');
        break;
      case 'Pote PET Preto':
        setFrascoImagem(frascoEncapsPreto);
        setFrascoSelecionado('Pote PET Preto');
        break;
      case 'Pote PET Amarelo':
        setFrascoImagem(frascoEncapsAmarelo);
        setFrascoSelecionado('Pote PET Amarelo');
        break;
      case 'Pote PET Azul/1':
        setFrascoImagem(frascoEncapsAzul1);
        setFrascoSelecionado('Pote PET Azul/1');
        break;
      case 'Pote PET Azul/2':
        setFrascoImagem(frascoEncapsAzul2);
        setFrascoSelecionado('Pote PET Azul/2');
        break;
      case 'Pote PET Azul/3':
        setFrascoImagem(frascoEncapsAzul3);
        setFrascoSelecionado('Pote PET Azul/3');
        break;
      case 'Pote PET Âmbar':
        setFrascoImagem(frascoEncapsAmbar);
        setFrascoSelecionado('Pote PET Âmbar');
        break;
      case 'Pote PET Fumê':
        setFrascoImagem(frascoEncapsFume);
        setFrascoSelecionado('Pote PET Fumê');
        break;
      case 'Pote PET Laranja':
        setFrascoImagem(frascoEncapsLaranja);
        setFrascoSelecionado('Pote PET Laranja');
        break;
      case 'Pote PET Roxo':
        setFrascoImagem(frascoEncapsRoxo);
        setFrascoSelecionado('Pote PET Roxo');
        break;
      case 'Pote PET Verde/1':
        setFrascoImagem(frascoEncapsVerdeEscuro);
        setFrascoSelecionado('Pote PET Verde/1');
        break;
      case 'Pote PET Verde/2':
        setFrascoImagem(frascoEncapsVerde2);
        setFrascoSelecionado('Pote PET Verde/2');
        break;
      case 'Pote PET Vermelho':
        setFrascoImagem(frascoEncapsVermelho);
        setFrascoSelecionado('Pote PET Vermelho');
        break;
      case 'Pote PET Violeta':
          setFrascoImagem(frascoEncapsVioleta);
          setFrascoSelecionado('Pote PET Violeta');
          break;
      case 'Cor Personalizada':
          setFrascoImagem(frascoEncapsTransparente);
          setFrascoSelecionado('Cor Personalizada');
          break;
    }
  }

  function handlerUpdateGummyOption(option:string) {
    switch(option) {
      case 'Sabor Framboesa':
        setGummyImagem(gummyAzul);
        setGummySelecionado('Sabor Framboesa');
        break;
      case 'Sabor Morango':
        setGummyImagem(gummyVermelho);
        setGummySelecionado('Sabor Morango');
        break;
      case 'Sabor Limão':
        setGummyImagem(gummyVerde);
        setGummySelecionado('Sabor Limão');
        break;
      case 'Sabor Abacaxi':
        setGummyImagem(gummyAmarelo);
        setGummySelecionado('Sabor Abacaxi');
        break;
      case 'Sabor Laranja':
        setGummyImagem(gummyLaranja);
        setGummySelecionado('Sabor Laranja');
        break;
      case 'Sabor Tutti Frutti':
        setGummyImagem(gummyRosa);
        setGummySelecionado('Sabor Tutti Frutti');
        break;
      case 'Sabor Uva':
        setGummyImagem(gummyRoxo);
        setGummySelecionado('Sabor Uva');
        break;
      case 'Sabor Personalizado':
        setGummyImagem(gummyTransparente);
        setGummySelecionado('Sabor Personalizado');
        break;
    }
  }

  function handleUpdateSoluvelOption(option:string) {
    switch(option) {
      case 'Pote PEAD Preto':
        setSoluvelImagem(potePretoSoluvel)
        setSoluvelSelecionado('Pote PEAD Preto')
        break;
      case 'Pote PEAD Branco':
        setSoluvelImagem(poteBrancoSoluvel)
        setSoluvelSelecionado('Pote PEAD Branco')
        break;
      case 'Pote PEAD Personalizado':
        setSoluvelImagem(poteBrancoSoluvel)
        setSoluvelSelecionado('Pote PEAD Personalizado')
        break;
    }
  }

  function handleUpdateLiquidoOption(option:string) {
    switch(option) {
      case 'Frasco Âmbar c/ Tampa Preta':
        setLiquidoImagem(frascoPretoLiquido)
        setLiquidoSelecionado('Frasco Âmbar c/ Tampa Preta')
        break;
      case 'Frasco Âmbar c/ Tampa Branca':
        setLiquidoImagem(frascoBrancoLiquido)
        setLiquidoSelecionado('Frasco Âmbar c/ Tampa Branca')
        break;
      case 'Frasco Personalizado':
        setLiquidoImagem(frascoBrancoLiquido)
        setLiquidoSelecionado('Frasco Personalizado')
        break;
    }
  }

  return (
    <>
      <Wrapper>
        <Content>
          <Title>
            <h1>Meu Projeto <IoRocketOutline size={30} /></h1>
            <p>Escolha a forma de criar seu produto com suas preferências escolhidas abaixo!</p>
          </Title>
          <Sections>
            <StepsOverview>
              <li>
                {currentStep === 0 ? (
                  <span onClick={() => handlerClearAndReturn(0)}><FiChevronsRight size={20} /> Tipo de Produto <FiChevronsLeft size={20} /></span>
                  ) : (
                  <span onClick={() => handlerClearAndReturn(0)}><b><FcOk size={20} /> Tipo de Produto</b></span>
                )}
              </li>
              <li>
                {currentStep === 0 && 'Fórmula/Princípio Ativo'}
                {currentStep === 1 && <span onClick={() => handlerClearAndReturn(1)}><FiChevronsRight size={20} /> Fórmula/Princípio Ativo <FiChevronsLeft size={20} /></span>}
                {currentStep >= 2 && <span onClick={() => handlerClearAndReturn(1)}><b><FcOk size={20} /> Fórmula/Princípio Ativo</b></span> }
              </li>
              {tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel' || tiposDeProduto === '' ? '' : (
                <>
                  <li>
                    {(currentStep === 0 || currentStep === 1) && tiposDeProduto === 'encapsulado' && 'Cor da Capsula'}
                    {currentStep === 2 && tiposDeProduto === 'encapsulado' && <span onClick={() => handlerClearAndReturn(2)}><FiChevronsRight size={20} /> Cor da Capsula <FiChevronsLeft size={20} /></span>}
                    {currentStep >= 3 && tiposDeProduto === 'encapsulado' && <span onClick={() => handlerClearAndReturn(2)}><b><FcOk size={20} /> Cor da Capsula</b></span> }
                    
                    {/* {(currentStep === 0 || currentStep === 1) && tiposDeProduto === 'gummy' && 'Cor da Goma'}
                    {currentStep === 2 && tiposDeProduto === 'gummy' && <span onClick={() => handlerClearAndReturn(2)}><FiChevronsRight size={20} /> Cor da Goma <FiChevronsLeft size={20} /></span>}
                    {currentStep >= 3 && tiposDeProduto === 'gummy' && <span onClick={() => handlerClearAndReturn(2)}><b><FcOk size={20} /> Cor da Goma</b></span> } */}
                  </li>
                </>
              )}
              <li>
                {(currentStep === 0 || currentStep === 1 || currentStep === 2) && 'Cor da Tampa e Pote'}
                {currentStep === 3 && tiposDeProduto !== 'soluvel' && tiposDeProduto !== 'liquido' && <span onClick={() => handlerClearAndReturn(3)}><FiChevronsRight size={20} /> Cor da Tampa e Pote <FiChevronsLeft size={20} /></span>}
                {(currentStep >= 4 || (currentStep === 3 && tiposDeProduto === 'soluvel') || (currentStep === 3 && tiposDeProduto === 'liquido')) && <span onClick={() => handlerClearAndReturn(3)}><b><FcOk size={20} /> Cor da Tampa e Pote</b></span> }
              </li>
              <li>
                {(currentStep === 0 || currentStep === 1 || currentStep === 2 || (currentStep === 3 && (tiposDeProduto === 'encapsulado' || tiposDeProduto === 'gummy'))) && 'Quantidades'}
                {(currentStep === 4 || (currentStep === 3 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel'))) && <span onClick={() => handlerClearAndReturn(3)}><FiChevronsRight size={20} /> Quantidades <FiChevronsLeft size={20} /></span>}
                {currentStep > 4 && <span onClick={() => handlerClearAndReturn(4)}><b><FcOk size={20} /> Quantidades</b></span> }
              </li>
            </StepsOverview>

            {currentStep === 0 ? (
              <Step01>
                <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha o tipo de produto:</h2></StepTitle>
                <TiposDeProduto>
                  <div onClick={() => { if(tiposDeProduto === 'encapsulado') setTiposDeProduto(''); else setTiposDeProduto('encapsulado') }}>
                    <img src={encapsuladosFoto} alt='Encapsulados'/>
                    <h1>Encapsulado {tiposDeProduto === 'encapsulado' ? <FiCheckCircle size={20} /> : '' }</h1>
                  </div>
                  <div onClick={() => { if(tiposDeProduto === 'soluvel') setTiposDeProduto(''); else setTiposDeProduto('soluvel') }}>
                    <img src={soluveisFoto} alt='Solúveis'/>
                    <h1>Solúvel {tiposDeProduto === 'soluvel' ? <FiCheckCircle size={20} /> : '' }</h1>
                  </div>
                  <div onClick={() => { if(tiposDeProduto === 'liquido') setTiposDeProduto(''); else setTiposDeProduto('liquido') }}>
                    <img src={liquidoFoto} alt='Líquidos'/>
                    <h1>Líquido {tiposDeProduto === 'liquido' ? <FiCheckCircle size={20} /> : '' }</h1>
                  </div>
                  {/* <div onClick={() => { if(tiposDeProduto === 'gummy') setTiposDeProduto(''); else setTiposDeProduto('gummy') }}>
                    <img src={gummyFoto} alt='Gummy'/>
                    <h1>Gummy {tiposDeProduto === 'gummy' ? <FiCheckCircle size={20} /> : '' }</h1>
                  </div> */}
                </TiposDeProduto>
              </Step01> ) : (
                <Step01>
                  <StepTitle><h2><FcOk size={30} /> Escolha o tipo de produto: 
                  <b>
                    {tiposDeProduto === 'encapsulado' && 'Encapsulado'}
                    {tiposDeProduto === 'soluvel' && 'Solúvel'}
                    {tiposDeProduto === 'liquido' && 'Líquido'}
                    {tiposDeProduto === 'gummy' && 'Gummy'}
                    </b></h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(0)}><FiRotateCw size={14} />Alterar o tipo de produto</h4>
                </Step01>
              )}
            {currentStep === 1 && tiposDeProduto === 'gummy' ? (
              <Step02>
              <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a fórmula que você deseja no seu produto:</h2></StepTitle>
              <TiposDeFormulas>
                <h2>Linha Gummy (Zero Açúcar)</h2>
                <TipoContent>
                  <div onClick={() => { if(formulaSelecionada === 'cabelo') setFormulaSelecionada(''); else setFormulaSelecionada('cabelo') }}>
                    <SiAtom size={30} />
                    <h1>Cabelo, Pele e Unhas / 1 {formulaSelecionada === 'cabelo' ? <FiCheckCircle size={20} /> : '' }</h1>
                    <p>ÁCIDO HIALURÔNICO, COLÁGENO HIDROLISADO & VITAMINA C</p>
                  </div>
                  <div onClick={() => { if(formulaSelecionada === 'cabelo2') setFormulaSelecionada(''); else setFormulaSelecionada('cabelo2') }}>
                    <SiAtom size={30} />
                    <h1>Cabelo, Pele e Unhas / 2 {formulaSelecionada === 'cabelo2' ? <FiCheckCircle size={20} /> : '' }</h1>
                    <p>VITAMINA A, VITAMINA B5, VITAMINA B6, VITAMINA B3, VITAMINA E, ZINCO, BIOTINA, IODO, SELÊNIO</p>
                  </div>
                  <div onClick={() => { if(formulaSelecionada === 'kids') setFormulaSelecionada(''); else setFormulaSelecionada('kids') }}>
                    <SiAtom size={30} />
                    <h1>Kids (Imunidade) {formulaSelecionada === 'kids' ? <FiCheckCircle size={20} /> : '' }</h1>
                    <p>VITAMINA C, VITAMINA D, VITAMINA E, VITAMINA A, VITAMINA B9, VITAMINA B12</p>
                  </div>
                  <div onClick={() => { if(formulaSelecionada === 'melatonina') setFormulaSelecionada(''); else setFormulaSelecionada('melatonina') }}>
                    <SiAtom size={30} />
                    <h1>Melatonina {formulaSelecionada === 'melatonina' ? <FiCheckCircle size={20} /> : '' }</h1>
                    <p>MELATONINA</p>
                  </div>
                </TipoContent>
              </TiposDeFormulas>
              </Step02>
            ) : ''}
            {currentStep === 1 && tiposDeProduto === 'soluvel' ? (
              <Step02>
              <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a fórmula que você deseja no seu produto:</h2></StepTitle>
              <TiposDeFormulas>
              <h2>Musculação</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'creatina') setFormulaSelecionada(''); else setFormulaSelecionada('creatina') }}>
                      <SiAtom size={30} />
                      <h1>Creatina {formulaSelecionada === 'creatina' ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'pretreino') setFormulaSelecionada(''); else setFormulaSelecionada('pretreino') }}>
                      <SiAtom size={30} />
                      <h1>Pré-Treino {formulaSelecionada === 'pretreino' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>CAFEÍNA, TAURINA, BETA ALANINA & L-ARGININA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'termogenico') setFormulaSelecionada(''); else setFormulaSelecionada('termogenico') }}>
                      <SiAtom size={30} />
                      <h1>Colágeno Verisol {formulaSelecionada === 'termogenico' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>ÁCIDO HIALURÔNICO</p>
                    </div>
                  </TipoContent>
              </TiposDeFormulas>
              </Step02>
            ) : ''}
            {currentStep === 1 && tiposDeProduto !== 'gummy' && tiposDeProduto !== 'soluvel' ? (
              <Step02>
                <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a fórmula que você deseja no seu produto:</h2></StepTitle>
                <TiposDeFormulas>
                  <h2>Beleza</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'acnes') setFormulaSelecionada(''); else setFormulaSelecionada('acnes') }}>
                      <SiAtom size={30} />
                      <h1>Acnes/Manchas na pele {formulaSelecionada === 'acnes' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>OLEA EUROPEA, TRANS RESVERATROL, PICNOGENOL & VITAMINA A</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'rugas') setFormulaSelecionada(''); else setFormulaSelecionada('rugas') }}>
                      <SiAtom size={30} />
                      <h1>Anti Envelhecimento e Rugas {formulaSelecionada === 'rugas' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>VITAMINA C, ÁCIDO HIALURÔNICO, ZINCO, SELÊNIO, SILÍCIO, COENZIMA Q 10 E TRANS RESVERATROL</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'autobronzeador') setFormulaSelecionada(''); else setFormulaSelecionada('autobronzeador') }}>
                      <SiAtom size={30} />
                      <h1>Autobronzeador {formulaSelecionada === 'autobronzeador' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>BETACAROTENO, LICOPENO, LUTEÍNA, VITAMINA E & COBRE</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'cabelo') setFormulaSelecionada(''); else setFormulaSelecionada('cabelo') }}>
                      <SiAtom size={30} />
                      <h1>Cabelo, Pele e Unha {formulaSelecionada === 'cabelo' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>VIT. A, VIT. B1, VIT. B12, VIT. B6, BIOTINA, VIT. D, PANTOTENATO DE CÁLCIO, FERRO, COBRE, ZINCO, SILÍCIO & CISTEÍNA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'celulite') setFormulaSelecionada(''); else setFormulaSelecionada('celulite') }}>
                      <SiAtom size={30} />
                      <h1>Celulite {formulaSelecionada === 'celulite' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>CAFEÍNA, CAFÉ VERDE & L-CARNITINA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'queda') setFormulaSelecionada(''); else setFormulaSelecionada('queda') }}>
                      <SiAtom size={30} />
                      <h1>Queda Capilar {formulaSelecionada === 'queda' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>BITOINA & COMPLEXO B</p>
                    </div>
                  </TipoContent>
                  <h2>Saúde em Geral</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'antiestresse') setFormulaSelecionada(''); else setFormulaSelecionada('antiestresse') }}>
                      <SiAtom size={30} />
                      <h1>Anti Estresse {formulaSelecionada === 'antiestresse' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>L-TRIPTOFANO, L-TEANINA & L-TIROSINA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'articulacoes') setFormulaSelecionada(''); else setFormulaSelecionada('articulacoes') }}>
                      <SiAtom size={30} />
                      <h1>Articulações {formulaSelecionada === 'articulacoes' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>COLÁGENO TIPO II, MSM, MAGNÉSIO & CURCUMA LONGA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'figado') setFormulaSelecionada(''); else setFormulaSelecionada('figado') }}>
                      <SiAtom size={30} />
                      <h1>Fígado {formulaSelecionada === 'figado' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>L-METIONINA, COLINA, INOSITOL & TAURINA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'imunidade') setFormulaSelecionada(''); else setFormulaSelecionada('imunidade') }}>
                      <SiAtom size={30} />
                      <h1>Imunidade {formulaSelecionada === 'imunidade' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>GLUTAMINA, VITAMINA C, VITAMINA D & ZINCO</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'multivitaminico') setFormulaSelecionada(''); else setFormulaSelecionada('multivitaminico') }}>
                      <SiAtom size={30} />
                      <h1>Multivitaminico A-Z & Sênior{formulaSelecionada === 'multivitaminico' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>VIT. A, VIT. D, VIT. C, VIT. E, VIT. B1, VIT. B2, VIT. B3, VIT. B6, ÁCIDO FÓLICO, VIT. B12, BIOTINA, ÁCIDO PANTETOTÊNICO, VIT. K, CÁLCIO, FERRO, MAGNÉSIO, ZINCO, IODO, COBRE, SELÊNIO, CROMO & MANGANÊS</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'prostata') setFormulaSelecionada(''); else setFormulaSelecionada('prostata') }}>
                      <SiAtom size={30} />
                      <h1>Próstata {formulaSelecionada === 'prostata' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>LICOPENO, ZINCO, MAGNÉSIO & AÇAÍ</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'qualidadesono') setFormulaSelecionada(''); else setFormulaSelecionada('qualidadesono') }}>
                      <SiAtom size={30} />
                      <h1>Qualidade do Sono {formulaSelecionada === 'qualidadesono' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>MELATONINA & L-TRIPTOFANO</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'ossos') setFormulaSelecionada(''); else setFormulaSelecionada('ossos') }}>
                      <SiAtom size={30} />
                      <h1>Ossos {formulaSelecionada === 'ossos' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>CÁLCIO, VITAMINA D & VITAMINA K</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'varizes') setFormulaSelecionada(''); else setFormulaSelecionada('varizes') }}>
                      <SiAtom size={30} />
                      <h1>Varizes {formulaSelecionada === 'varizes' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>PICNOGENOL, L-ARGININA, MAGNÉSIO & VITAMINA B12</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'tpm') setFormulaSelecionada(''); else setFormulaSelecionada('tpm') }}>
                      <SiAtom size={30} />
                      <h1>TPM & Menopausa {formulaSelecionada === 'tpm' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>BORRAGEM, FENO GREGO, FERRO & L-TRIPTOFANO</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'libido') setFormulaSelecionada(''); else setFormulaSelecionada('libido') }}>
                      <SiAtom size={30} />
                      <h1>Estimulante Sexual - Libído & Estimulante Hormonal {formulaSelecionada === 'libido' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>FENO GREGO, L-ARGININA, VITAMINA B3 & VITAMINA D</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'foco') setFormulaSelecionada(''); else setFormulaSelecionada('foco') }}>
                      <SiAtom size={30} />
                      <h1>Foco, Memória & Concentração {formulaSelecionada === 'foco' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>ÁCIDO GLUTÂMICO, CAFEÍNA, FOSFATIDILSERINA & GUARANÁ</p>
                    </div>
                  </TipoContent>
                  <h2>Emagrecimento</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'digestivo') setFormulaSelecionada(''); else setFormulaSelecionada('digestivo') }}>
                      <SiAtom size={30} />
                      <h1>Digestivo {formulaSelecionada === 'digestivo' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>BLEND DE VITAMINAS DIGESTIVAS</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'diuretico') setFormulaSelecionada(''); else setFormulaSelecionada('diuretico') }}>
                      <SiAtom size={30} />
                      <h1>Diurético {formulaSelecionada === 'diuretico' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>BLEND DE VITAMINAS DIURÉTICAS</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'queima') setFormulaSelecionada(''); else setFormulaSelecionada('queima') }}>
                      <SiAtom size={30} />
                      <h1>Queima de Gordura {formulaSelecionada === 'queima' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>LARANJA MORO, L-CARNITINA & CAFÉ VERDE</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'apetite') setFormulaSelecionada(''); else setFormulaSelecionada('apetite') }}>
                      <SiAtom size={30} />
                      <h1>Controle de Apetite {formulaSelecionada === 'apetite' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>PICOLINATO DE CROMO, ESPIRULINA, QUITOSANA & PSYLLIUM</p>
                    </div>
                  </TipoContent>
                  <h2>Musculação</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'creatina') setFormulaSelecionada(''); else setFormulaSelecionada('creatina') }}>
                      <SiAtom size={30} />
                      <h1>Creatina {formulaSelecionada === 'creatina' ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'pretreino') setFormulaSelecionada(''); else setFormulaSelecionada('pretreino') }}>
                      <SiAtom size={30} />
                      <h1>Pré-Treino {formulaSelecionada === 'pretreino' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>CAFEÍNA, TAURINA, BETA ALANINA & L-ARGININA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'bcaa') setFormulaSelecionada(''); else setFormulaSelecionada('bcaa') }}>
                      <SiAtom size={30} />
                      <h1>BCAA {formulaSelecionada === 'bcaa' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>LEUCINA, ISOLEUCINA & VALINA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'termogenico') setFormulaSelecionada(''); else setFormulaSelecionada('termogenico') }}>
                      <SiAtom size={30} />
                      <h1>Termogênico {formulaSelecionada === 'termogenico' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>CAFEÍNA, CHÁ VERDE, CITRUS AURANTIUM & SPIRULINA</p>
                    </div>
                    <div onClick={() => { if(formulaSelecionada === 'colageno verisol') setFormulaSelecionada(''); else setFormulaSelecionada('colageno verisol') }}>
                      <SiAtom size={30} />
                      <h1>Colágeno Verisol {formulaSelecionada === 'colageno verisol' ? <FiCheckCircle size={20} /> : '' }</h1>
                      <p>ÁCIDO HIALURÔNICO</p>
                    </div>
                  </TipoContent>
                  <h2>Personalizada</h2>
                  <TipoContent>
                    <div onClick={() => { if(formulaSelecionada === 'personalizada') setFormulaSelecionada(''); else setFormulaSelecionada('personalizada') }}>
                      <SiAtom size={30} />
                      <h1>Fórmula Personalizada {formulaSelecionada === 'personalizada' ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>
                  </TipoContent>
                </TiposDeFormulas>
              </Step02> ) : (
                currentStep >= 2 && 
                <Step02>
                  <StepTitle>
                    <h2><FcOk size={30} /> Fórmula selecionada!</h2>
                  </StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(1)}><FiRotateCw size={14} />Alterar a fórmula selecionada</h4>
                </Step02>
            )}
            {currentStep === 2 && (tiposDeProduto === 'liquido') ? (
              <Step03>
                <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a cor da tampa do frasco:</h2></StepTitle>
                <TipoCapsula>
                  <div>
                    <img src={liquidoImagem} alt='Frasco Líquido'/>
                    <h5>{liquidoSelecionado}</h5>
                    <div>
                      <FaCircle onClick={() => handleUpdateLiquidoOption('Frasco Âmbar c/ Tampa Branca')} size={30} color='#fff' />
                      <FaCircle onClick={() => handleUpdateLiquidoOption('Frasco Âmbar c/ Tampa Preta')} size={30} color='#000' />
                      <CgEditContrast onClick={() => handleUpdateLiquidoOption('Frasco Personalizado')} size={30} color='rgb(32,38,69)' />
                    </div>
                  </div>
                </TipoCapsula>
              </Step03> ) : (
                currentStep >= 3 && tiposDeProduto === 'liquido' &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Cor da tampa e frasco selecionada!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(2)}><FiRotateCw size={14} />Alterar a cor do pote</h4>
                </Step03>
              )}
            {currentStep === 2 && (tiposDeProduto === 'soluvel') ? (
              <Step03>
                <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a cor do pote:</h2></StepTitle>
                <TipoCapsula>
                  <div>
                    <img src={soluvelImagem} alt='Pote Solúvel'/>
                    <h5>{soluvelSelecionado}</h5>
                    <div>
                      <FaCircle onClick={() => handleUpdateSoluvelOption('Pote PEAD Branco')} size={30} color='#fff' />
                      <FaCircle onClick={() => handleUpdateSoluvelOption('Pote PEAD Preto')} size={30} color='#000' />
                      <CgEditContrast onClick={() => handleUpdateSoluvelOption('Pote PEAD Personalizado')} size={30} color='rgb(32,38,69)' />
                    </div>
                  </div>
                </TipoCapsula>
              </Step03> ) : (
                currentStep >= 3 && tiposDeProduto === 'soluvel' &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Cor do pote selecionado!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(2)}><FiRotateCw size={14} />Alterar a cor do pote</h4>
                </Step03>
              )}
            {currentStep === 2 && (tiposDeProduto === 'encapsulado') ? (
              <Step03>
                <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a cor da capsula:</h2></StepTitle>
                <TipoCapsula>
                  <div>
                    <img src={capsulaImagem} alt='Capsula'/>
                    <h5>{capsulaSelecionada}</h5>
                    <div>
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Transparente')} size={30} color='#d8d8d8' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Branca')} size={30} color='#fff' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Preta')} size={30} color='#000' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Verde')} size={30} color='#04cd6c' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Azul')} size={30} color='#00a2de' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Azul-Turquesa')} size={30} color='#016384' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Laranja')} size={30} color='#ff6d33' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Amarela')} size={30} color='#f2ba03' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Verde Escuro')} size={30} color='#00471c' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Vermelha')} size={30} color='#e5011e' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Vinho')} size={30} color='#7e3447' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Rosa')} size={30} color='#ff16f1' />
                      <FaCircle onClick={() => handleUpdateCapsulaOption('Roxa')} size={30} color='#ad11e5' />
                      <CgEditContrast onClick={() => handleUpdateCapsulaOption('Cor Personalizada')} size={30} color='rgb(32,38,69)' />
                    </div>
                  </div>
                </TipoCapsula>
              </Step03> ) : (
                currentStep >= 3 && tiposDeProduto === 'encapsulado' &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Cor da capsula selecionada!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(2)}><FiRotateCw size={14} />Alterar a cor da capsula</h4>
                </Step03>
              )}
              {currentStep === 2 && (tiposDeProduto === 'gummy') ? (
                <Step03>
                  <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha o sabor da goma:</h2></StepTitle>
                  <TipoCapsula>
                    <div>
                      <img src={gummyImagem} alt='Gummy'/>
                      <h5>{gummySelecionado}</h5>
                      <div>
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Limão')} size={30} color='#04cd6c' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Framboesa')} size={30} color='#00a2de' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Laranja')} size={30} color='#ff6d33' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Abacaxi')} size={30} color='#f2ba03' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Morango')} size={30} color='#e5011e' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Tutti Frutti')} size={30} color='#ff16f1' />
                        <FaCircle onClick={() => handlerUpdateGummyOption('Sabor Uva')} size={30} color='#af00c4' />
                        <CgEditContrast onClick={() => handlerUpdateGummyOption('Sabor Personalizado')} size={30} color='rgb(32,38,69)' />
                      </div>
                    </div>
                  </TipoCapsula>
                </Step03> ) : (
                currentStep >= 3 && tiposDeProduto === 'gummy' &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Cor da goma selecionada!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(2)}><FiRotateCw size={14} />Alterar a cor da goma</h4>
                </Step03>
              )}
            {currentStep === 3 && (tiposDeProduto === 'encapsulado' || tiposDeProduto === 'gummy') ? (
              <Step04>
                <StepTitle>
                  <h2><FiHexagon size={30} color='#1c80f3' /> Escolha a cor da tampa e pote: </h2>
                  <p>Tamanhos: 120ML, 170ML, 220ML</p>
                </StepTitle>
                <TipoCapsula>
                  <DivisaoCapsula>
                    <img src={tampaImagem} alt='Tampa'/>
                    <h5>{tampaSelecionada}</h5>
                    <div>
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Lisa Branca')} size={30} color='#fff' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Lisa Preta')} size={30} color='#000' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Cristal')} size={30} color='#c5c5c5' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Azul/1')} size={30} color='#223162' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Azul/2')} size={30} color='#00a2de' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Amarela/1')} size={30} color='#e9ad00' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Amarela/2')} size={30} color='#e7bc55' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Amarela/3')} size={30} color='#fbd400' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Laranja/1')} size={30} color='#ff3422' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Laranja/2')} size={30} color='#eb3013' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Laranja/3')} size={30} color='#ff9900' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Rosa/1')} size={30} color='#ff059e' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Rosa/2')} size={30} color='#ff50a8' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Prata')} size={30} color='#8d8d8d' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Preta')} size={30} color='#000' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Branca')} size={30} color='#fff' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Verde/1')} size={30} color='#008a7e' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Verde/2')} size={30} color='#005c00' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Verde/3')} size={30} color='#5fbc00' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Violeta')} size={30} color='#560194' />
                      <FaCircle onClick={() => handleUpdateTampaOption('Tampa Flip Top Vermelha')} size={30} color='#cb001c' />
                      <CgEditContrast onClick={() => handleUpdateTampaOption('Cor Personalizada')} size={30} color='rgb(32,38,69)' />
                    </div>
                  </DivisaoCapsula>
                  <DivisaoCapsula>
                    <img src={frascoImagem} alt='Frasco'/>
                    <h5>{frascoSelecionado}</h5>
                    <div>
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Cristal')} size={30} color='#d8d8d8' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Branco')} size={30} color='#fff' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Preto')} size={30} color='#000' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Verde/1')} size={30} color='#0a551e' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Verde/2')} size={30} color='#1a524b' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Azul/1')} size={30} color='#00caea' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Azul/2')} size={30} color='#0068cf' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Azul/3')} size={30} color='#05d9d7' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Laranja')} size={30} color='#ff6d33' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Amarelo')} size={30} color='#f2ba03' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Fumê')} size={30} color='#464646' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Âmbar')} size={30} color='#7e3447' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Roxo')} size={30} color='#ad1687' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Vermelho')} size={30} color='#ff0001' />
                      <FaCircle onClick={() => handlerUpdateFrascoOption('Pote PET Violeta')} size={30} color='#251358' />
                      <CgEditContrast onClick={() => handlerUpdateFrascoOption('Cor Personalizada')} size={30} color='rgb(32,38,69)' />
                    </div>
                  </DivisaoCapsula>
                </TipoCapsula>
              </Step04> ) : (
                currentStep >= 4 && (tiposDeProduto === 'encapsulado' || tiposDeProduto === 'gummy') &&
                <Step04>
                  <StepTitle><h2><FcOk size={30} /> Cor da tampa e pote selecionada! </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(3)}><FiRotateCw size={14} />Alterar a cor da tampa ou do pote</h4>
                </Step04>
              )}
              {(currentStep === 4 && (tiposDeProduto === 'gummy' || tiposDeProduto === 'encapsulado')) || (currentStep === 3 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel'))? (
                <Step06>
                  <StepTitle><h2><FiHexagon size={30} color='#1c80f3' /> Escolha a quantidade de unidades:</h2></StepTitle>
                  <TiposDeProduto>
                    {tiposDeProduto !== 'gummy' && <div onClick={() => { if(quantidade === 250) setQuantidade(0); else setQuantidade(250) }}>
                      <h1>250 <small>unidades</small> {quantidade === 250 ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>}
                    {tiposDeProduto !== 'gummy' && <div onClick={() => { if(quantidade === 500) setQuantidade(0); else setQuantidade(500) }}>
                      <h1>500 <small>unidades</small>  {quantidade === 500 ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>}
                    {tiposDeProduto !== 'gummy' && <div onClick={() => { if(quantidade === 1000) setQuantidade(0); else setQuantidade(1000) }}>
                      <h1>1000 <small>unidades</small>  {quantidade === 1000 ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>}
                    <div onClick={() => { if(quantidade === 2000) setQuantidade(0); else setQuantidade(2000) }}>
                      <h1>2000 <small>unidades</small> {quantidade === 2000 ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>
                    {tiposDeProduto !== 'gummy' && <div onClick={() => { if(quantidade === 999) setQuantidade(0); else setQuantidade(999) }}>
                      <h1>Outra quantidade {quantidade === 999 ? <FiCheckCircle size={20} /> : '' }</h1>
                    </div>}
                  </TiposDeProduto> 

                  {tiposDeProduto === 'encapsulado' ? (
                    <>
                      <StepTitle><h2><BiCapsule size={30} color='#1c80f3' /> Escolha a quantidade de capsulas:</h2></StepTitle>
                      <TiposDeProduto>
                        <div onClick={() => { if(capsulasQtde === 60) setCapsulasQtde(0); else setCapsulasQtde(60) }}>
                          <h1>60 <small>capsulas</small>  {capsulasQtde === 60 ? <FiCheckCircle size={20} /> : '' }</h1>
                        </div>
                      </TiposDeProduto>
                    </>
                  ): ''}
                  {tiposDeProduto === 'gummy' ? (
                    <>
                      <StepTitle><h2><BiCapsule size={30} color='#1c80f3' /> Escolha a quantidade de gomas:</h2></StepTitle>
                      <TiposDeProduto>
                        <div onClick={() => { if(capsulasQtde === 30) setCapsulasQtde(0); else setCapsulasQtde(30) }}>
                          <h1>30 <small>gomas</small> <FiCheckCircle size={20} /></h1>
                        </div>
                      </TiposDeProduto>
                    </>
                  ): ''}
                  {tiposDeProduto === 'soluvel' ? (
                    <>
                      <StepTitle><h2><BiCapsule size={30} color='#1c80f3' /> Escolha a quantidade do pote:</h2></StepTitle>
                      <TiposDeProduto>
                        <div onClick={() => { if(capsulasQtde === 150) setCapsulasQtde(0); else setCapsulasQtde(150) }}>
                          <h1>150 <small>g</small> {capsulasQtde === 150 ? <FiCheckCircle size={20} /> : '' }</h1>
                        </div>
                        <div onClick={() => { if(capsulasQtde === 300) setCapsulasQtde(0); else setCapsulasQtde(300) }}>
                          <h1>300 <small>g</small> {capsulasQtde === 300 ? <FiCheckCircle size={20} /> : '' }</h1>
                        </div>
                      </TiposDeProduto>
                    </>
                  ): ''}
                  {tiposDeProduto === 'liquido' ? (
                    <>
                      <StepTitle><h2><BiCapsule size={30} color='#1c80f3' /> Escolha a quantidade de líquido:</h2></StepTitle>
                      <TiposDeProduto>
                        <div onClick={() => { if(capsulasQtde === 30) setCapsulasQtde(0); else setCapsulasQtde(30) }}>
                          <h1>30 <small>mls</small> <FiCheckCircle size={20} /></h1>
                        </div>
                      </TiposDeProduto>
                    </>
                  ): ''}
                </Step06> ) : (
                (currentStep === 5 || (currentStep === 4 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel'))) &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Quantidade selecionada!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(4)}><FiRotateCw size={14} />Alterar a quantidade</h4>
                </Step03>
              )}
              {currentStep === 5 || (currentStep === 4 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel')) ? (
                <Step07>
                  <h2><FiCheckSquare size={20} color='#1c80f3' />Insira seus dados para obter o orçamento no seu E-mail e WhatsApp</h2>
                  <FormualarioEnvio>
                    <>
                      <form action="https://service.capsulecrm.com/service/newlead" accept-charset="UTF-8" method="post">
                        <input type="hidden" name="FORM_ID" value="cd82bb13-d781-4b31-85ac-5802d9c45f3b" />
                        <input type="hidden" name="COMPLETE_URL" value="https://icapsulas.com.br" />
                        <FormGroup>
                          <input placeholder='Nome' type="text" name="FIRST_NAME" />
                          <input placeholder='Sobrenome' type="text" name="LAST_NAME" />
                        </FormGroup>
                        <FormGroup>
                          <input placeholder='E-mail' type="email" name="EMAIL" />
                          <input placeholder='WhatsApp' type="text" name="PHONE" />
                        </FormGroup>
                        <input type="hidden" name="NOTE" value={selecoesFormulario} />
                        <FormSubmit><input type="submit" value="Enviar" /></FormSubmit>
                      </form>
                    </>
                  </FormualarioEnvio>
                </Step07> ) : (
                (currentStep > 4 || (currentStep === 4 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel'))) &&
                <Step03>
                  <StepTitle><h2><FcOk size={30} /> Quantidade selecionada!
                  </h2></StepTitle>
                  <h4 onClick={() => handlerClearAndReturn(5)}><FiRotateCw size={14} />Alterar a quantidade</h4>
                </Step03>
              )}
          </Sections>
          {currentStep === 5 || (currentStep === 4 && (tiposDeProduto === 'liquido' || tiposDeProduto === 'soluvel')) ? '' : (
            <Next>
              {(currentStep === 0 && tiposDeProduto === '') || (currentStep === 1 && formulaSelecionada === '') ? ( 
                <button type='button' disabled={tiposDeProduto === ''} style={{cursor: 'not-allowed'}}>Próximo <FiChevronRight size={20} /></button>
              ) : (
                <button type='button' onClick={() => setCurrentStep(currentStep + 1)}>Próximo <FiChevronRight size={20} /></button>
              )}
            </Next>
          )}
        </Content>

        <Footer>
          <p>Copyright © {new Date().getFullYear()} iCapsulas. Todos direitos reservados.</p>
          <img src={logo2} alt='iCapsulas' />
        </Footer>
      </Wrapper>
    </>
  )
}
