import React from 'react'
import { MdAssessment, MdLogout } from 'react-icons/md'
import { FaFire } from 'react-icons/fa'
import { Content, Wrapper, IconContent, MenuContent, WhatsAppButton, LiUserLogged, UserLogged, IconAtendimento } from './styles'
import logo2 from '../../assets/footer-logo.png'
import iconAtendimento from '../../assets/icon-atendimento.png'
import { ActiveMenuContext } from '../../contexts/activeMenu';
import { HashLink as Link } from 'react-router-hash-link'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../contexts/auth';

export function Header({ isLogged }:{ isLogged?: boolean }) {
  const { activeMenu, setActiveMenu } = React.useContext(ActiveMenuContext)
  const { auth, handleLogout } = React.useContext(AuthContext)

  const whats = [
    'https://wa.me/555436981209',
  ]

  const random = Math.floor(Math.random() * whats.length);

  const history = useHistory();

  function handleGoToOrcamento() {
    history.push('/orcamento')
  }

  return (
    <Wrapper>
      <Content>
        {isLogged && !auth.admin ? 
          <>
            <Link onClick={() => setActiveMenu('home')} to='/shop/my-dash'><img src={logo2} alt='iCapsulas' /></Link>
            <MenuContent>
              <LiUserLogged><Link to='/shop/my-dash'><MdAssessment size={30} /></Link></LiUserLogged>
            </MenuContent>
          </> :
          <>
            <Link onClick={() => setActiveMenu('home')} to='/'><img src={logo2} alt='iCapsulas' /></Link>
            <MenuContent>
              <li onClick={() => setActiveMenu('home')}><Link to='/'>{activeMenu === 'home' ? <span>Início</span> : 'Início'}</Link></li>
              <li onClick={() => setActiveMenu('services')}><Link to='/#servicos'>{activeMenu === 'services' ? <span>Nossos serviços</span> : 'Nossos serviços'}</Link></li>
              <li onClick={() => setActiveMenu('about')}><Link to='/#sobre'>{activeMenu === 'about' ? <span>Quem somos</span> : 'Quem somos'}</Link></li>
              <li onClick={() => setActiveMenu('contact')}><Link to='/#contato'>{activeMenu === 'contact' ? <span>Contato</span> : 'Contato'}</Link></li>
            </MenuContent>
          </>
        }
        <IconContent>
          {isLogged && !auth.admin ?
            <UserLogged>
              <h4>{auth.name.replaceAll('"', "")}</h4>
              <Link onClick={() => handleLogout()} to='#'><MdLogout size={20} /> Sair</Link>
            </UserLogged> :
              <>
              {/* <IconAtendimento>
                <a target='_blank' href={whats[random]} rel="noopener noreferrer"><img src={iconAtendimento} alt='Atendimento'/></a>
              </IconAtendimento> */}
              <a href='#'rel="noopener noreferrer" onClick={handleGoToOrcamento}><WhatsAppButton><FaFire size={16} /> QUERO CRIAR MEU PRODUTO</WhatsAppButton></a>
            </>
          }
        </IconContent>
      </Content>
    </Wrapper>
  )
}
