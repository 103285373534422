import React from 'react'
import { MdAssessment, MdLogout } from 'react-icons/md'
import { FaFire } from 'react-icons/fa'
import { Content, Wrapper, IconContent, MenuContent, WhatsAppButton, LiUserLogged, UserLogged, IconAtendimento } from './styles'
import logo2 from '../../assets/footer-logo.png'
import iconAtendimento from '../../assets/icon-atendimento.png'
import { ActiveMenuContext } from '../../contexts/activeMenu';
import { HashLink as Link } from 'react-router-hash-link'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../contexts/auth';

export function Header2({ isLogged }:{ isLogged?: boolean }) {
  const { activeMenu, setActiveMenu } = React.useContext(ActiveMenuContext)
  const { auth, handleLogout } = React.useContext(AuthContext)

  const whats = [
    'https://wa.me/555436981209',
  ]

  const random = Math.floor(Math.random() * whats.length);

  const history = useHistory();

  function handleGoToOrcamento() {
    history.push('/orcamento')
  }

  return (
    <Wrapper>
      <Content>
        {isLogged && !auth.admin ? 
          <>
            <Link onClick={() => setActiveMenu('home')} to='/shop/my-dash'><img src={logo2} alt='iCapsulas' /></Link>
            <MenuContent>
              <LiUserLogged><Link to='/shop/my-dash'><MdAssessment size={30} /></Link></LiUserLogged>
            </MenuContent>
          </> :
          <>
            <MenuContent>
            </MenuContent>
            <Link to='/marcamilionaria'><img src={logo2} alt='iCapsulas' /></Link>
          </>
        }
        <IconContent>
          
        </IconContent>
      </Content>
    </Wrapper>
  )
}
