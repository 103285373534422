import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 10px;

  svg {
    animation: ${rotate} 1s linear infinite;
  }
`
