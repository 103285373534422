import styled from 'styled-components'

export const Wrapper = styled.div`
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 70px;

  a {
    text-decoration: none;
  }

  .pulse {
    transform: scale(1);
    animation: float 1s infinite;
  
    @keyframes float {
      0% {
        padding-top: 10px;
      }

      20% {
        padding-top: 6px;
      }

      50% {
        padding-top: 2px;
      }

      100% {
        padding-top: 0px;
      }
    }
  }

  h5 {
    svg {
      opacity: .8;
      transform: scale(1);
      animation: float 1s infinite;
  
      @keyframes float {
        0% {
          padding-top: 10px;
        }
  
        50% {
          padding-top: 2px;
        }
  
        100% {
          padding-top: 0px;
        }
      }
    }
  }

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: block;
  margin: 0 auto;
  color: white;
  font-size: 30px;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);

  div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      color: #ffffff;
      transition: 0.2s;
      
      &:hover {
        opacity: .9;
        transition: 0.2s;
      }
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 60px;
    padding: 30px;
  }

  img {
    width: 300px;
    transition: 0.2s;
    margin-top: 30px;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }
`

export const Content = styled.div`
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;

  h2 {
    background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 5px;

    font-size: 30px;
    padding: 15px;
  }

  p {
    color: rgba(32, 38, 69, 1);
    padding: 15px;
    font-weight: bold;
  }

  a {
    span {
      color: white !important;
    }
  }

  span {
    color: rgba(28, 128, 243, 1);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;

    svg {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`

export const Title = styled.div`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;

  h1 {
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 40px;
    color: #0b1526;
    margin-bottom: 15px;
    align-items: center;
    background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

    svg {
      margin-left: 10px;
    }
  }

  p {
    color: #0b1526;
    font-size: 20px;
    font-weight: bold;

    span {
      text-decoration: underline;
    }
  }
`

export const SectionPanelClient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  p {
    margin-bottom: 0px !important;
    margin-right: 10px;
  }

  @media (max-width: 775px) {
    display: block !important;
    text-align: center;

    p {
      text-align: left;
      margin-bottom: 10px !important;
    }
  }
`

export const SectionPanelItems = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  border: 1px solid #00b7ff;
  border-radius: 40px;

  ul {
    list-style: none;

    li {
      text-decoration: none;
    }
  }

  @media (max-width: 775px) {
    display: block !important;
  }
`

export const Sections3 = styled.div`
  display: block;
  background: linear-gradient(90deg, rgba(32,101,255,1) 0%, rgba(32,38,69,1) 100%);
  width: 100%;

  svg {
    margin-bottom: 30px;
  }
`

export const Sections6 = styled.div`
  display: block;
  background: #d2d2d2;
  width: 100%;
  padding-top: 30px;
  background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: 35px;

  h4 {
    padding-left: 15px;
    padding-right: 15px;
  }

  img {
    max-width: 100%;
    margin-bottom: -6px;
  }
`

export const Sections7 = styled.form`
  display: block;
  background: white;
  padding: 20px;

  h2, p, h4 {
    color: rgba(32,38,69,1);
  }

  span {
    background: #ededed;
    text-align: center;
    font-size: 20px;
    color: rgba(32,38,69,1);
    padding: 10px;
    border-radius: 20px;
    font-weight: bold;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;

    small {
      font-weight: 200;
    }
  }
`

export const SectionFormGroup = styled.div`
  display: block;
  background: #ededed;
  padding: 15px;
  border-radius: 20px;
  margin-top: 15px;
  
  h4 {
    font-size: 20px;
    text-align: left;
    font-weight: 200;
    margin-bottom: 5px;
  }

  input, textarea {
    width: 100%;
    border-radius: 30px;
    border: none;
    padding: 10px;
    font-size: 20px;
    color: rgba(32,38,69,1);
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid #ededed;

    &:focus {
      outline: none;
      border: 2px solid rgba(32,38,69,1);
    }
  }

  input[type="checkbox"] {
    width: 20px;
  }

  div {
    display: inline-flex;
    margin-right: 50px;

    p {
      margin-left: 10px;
      font-size: 20px;
    }
  }

  h5 {
    font-size: 20px;
    text-align: left;
    font-weight: 200;
    margin-bottom: 5px;
    margin-top: 30px;
    color: rgba(32,38,69,1);
  }

  a {
    span {
      color: white;
      background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    }
  }

  button {
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    border: none;
    background: linear-gradient(90deg, rgba(32,38,69,1) 0%, rgba(28,128,243,1) 100%);
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: 0.5s;
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
    color: #ffff;
    cursor: pointer !important;
    margin-bottom: 20px;
    font-weight: bold;

    svg {
      margin-left: 5px;
      margin-right: 5px;
    }

    &:hover {
      transition: 0.5s;
      background: linear-gradient(90deg, rgba(28,128,243,1) 0%, rgba(32,38,69,1) 100%);
    }
  }
`

export const SectionCheckbox = styled.div`
  display: inline-flex;
  width: 100%;
  text-align: left;
  margin-right: 0px !important;
  margin-top: 10px;
`

export const Sections5 = styled.div`
  display: block;
  background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  svg {
    margin-bottom: 30px;
  }

  div {
    padding: 30px;

    p {
      font-weight: bold;
      text-align: justify;
    }

    h2 {
      margin-bottom: 5px;
    }

    img {
      max-width: 100%;
      border-radius: 50px;
      margin-bottom: 10px;
    }
  }
`

export const SectionUmaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  padding: 50px;

  div {
    text-align: left;
    padding: 20px;
    border: 3px solid #00b7ff;
    border-radius: 25px;

    h2 {
      margin-bottom: 0px;
      span {
        color: #00ff00;
      }
    }

    h4 {
      font-size: 40px;
      text-align: center;
      color: #00ff00;
    }
  }

  @media (max-width: 775px) {
    display: block;
    padding: 20px;

    h3 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    div > h4 > span {
      font-size: 27px;
    }
  }
`

export const SectionDuasGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    padding: 50px;

    div {
      text-align: left;
      padding: 20px;
      border: 3px solid #00b7ff;
      border-radius: 25px;

      h2 {
        text-align: left;
        
        span {
          color: #00b7ff;
        }
      }

      h1 {
        color: #00ff00;
        font-weight: bold;
        font-size: 30px;
      }
    }

  @media (max-width: 775px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;
  }
`

export const SectionsNumeros = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding: 30px;

  div {
    h2 {
      text-align: center;
    }

    p {
      font-size: 20px;
    }

    img {
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
`

export const Sections2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  
  div {
    padding-bottom: 50px;
    padding-left: 30px;
  }

  h2 {
    font-size: 35px;
    background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  a > span {
    margin-top: 30px;
    max-width: 300px;
  }

  p {
    font-size: 25px;
  }

  h2, p {
    text-align: left;
    color: black;
  }

  img {
    position: relative;
    max-width: 300px;
  }

  @media (max-width: 775px) {
    img {
      display: none;
    }
  }
`

export const SectionsPanel = styled.div`
  display: block;
  margin-top: 30px;
  padding: 0px !important;
`

export const Sections = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);

  div {
    padding: 10px;
  }

  img {
    max-width: 300px;
  }

  h2 {
    text-align: left;
    font-size: 32px;
  }

  p {
    font-size: 15px;
    margin-bottom: 10px;
  }

  span {
    font-size: 25px;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(0,183,255,1) 0%, rgba(172,198,255,1) 100%);
    text-decoration: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  @media (max-width: 775px) {
    display: block !important;

    img {
      display: none;
    }
  }
`

export const SectionsDifDiv = styled.div`
  text-align: 'left';
  max-width: 60%;

  @media (max-width: 775px) {
    max-width: 100% !important;
    text-align: left;
  }

  p {
    font-size: 18px;
  }
`

export const StepsOverview = styled.ul`
  max-width: 1200px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;

  li {
    display: inline;
    margin: 0 0 0 15px; 
    list-style: none;
    color: #c7c7c7;
    font-size: 15px;
    padding: 10px;
    cursor: pointer;

    span {
      color: #1c80f3;
      border-radius: 3px;
      display: flex;
      align-items: center;

      b {
        display: flex;
        align-items: center;
        color: #6e8b00;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar {
    width: 1px !important;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
`
export const Step01 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

export const Step02 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step03 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step04 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step05 = styled.div``
export const Step06 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h1 {
    small {
      font-size: 14px;
      opacity: 0.6;
      margin-left: 5px;
    }
  }

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step07 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h1 {
    small {
      font-size: 14px;
      opacity: 0.6;
      margin-left: 5px;
    }
  }

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h2 {
    color: #0b1526;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 15px;
    padding: 5px;
    
    svg {
      margin-right: 10px;
    }
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

export const FormualarioEnvio = styled.div`
  display: block;

  form {
    padding: 10px;
    margin-top: 20px;
    color: #000;
    display: block;

    div {
      padding: 10px;
      text-align: start;

      input {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px;
        border: 1px solid #b2b2b2;
        border-radius: 5px;
        background: transparent;
      }
    }
  }
`

export const FormSubmit = styled.div`
  padding: 10px !important;
  display: flex;
  width: 100%;

  input {
    margin-right: 15px;
    background: #1d80f3 !important;
    color: #fff;
    border: 1px solid #1d80f3 !important;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      opacity: 0.8;
    }
  }
`

export const TipoCapsula = styled.div`
  max-width: 1200px;
  display: inline-flex;
  margin: 0 auto;
  padding: 20px;
  transition: 0.3s;

  @media (max-width: 725px) {
    display: block !important;
  }

  div {
    padding: 10px;

    span {
      display: inline-flex;
      border-radius: 5px;
      padding: 10px;
    }

    #active {
      border: 2px solid #1c80f3 !important;
    }

    svg {
      cursor: pointer;
      margin-left: 10px;
      border: 2px solid #fff;
      border-radius: 15px;
      box-shadow: 2px 2px 5px #aaa;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  img {
    max-width: 300px;
    transition: 0.3s;
  }
`

export const TipoContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 20px;

  @media (max-width: 750px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (max-width: 619px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  &:last-child {
    border-bottom: 0px;
  }

  h1 {
    font-size: 16px !important;
  }

  div {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    cursor: pointer;
    background: #f0f0f0;
    padding: 10px;
    transition: 0.2s;

    p {
      color: #919090;
      font-size: 10px;
    }

    &:hover {
      transition: 0.2s;
      border: 1px solid #1c80f3;
      background: #1c80f3;

      p {
        color: #f5f5f5;
        font-size: 10px;
        opacity: 0.8;
      }
      
      h1 {
        color: #fff;
      }

      svg {
        color: #fff;
      }
    }

    svg {
      color: #0b1526;
    }
  }
`

export const TiposDeFormulas = styled.div`
  max-width: 1200px;
  display: block;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;

  h2 {
    display: flex;
    max-width: 1200px;
    text-align: left;
    justify-content: start;
    font-weight: 200;
    color: #0b1526;
    font-size: 16px;
    opacity: 0.8;

    small {
      color: #1c80f3;
      margin-left: 10px;
      text-decoration: underline;
    }
  }

  div {
    h1 {
      font-weight: 200;
      color: #0b1526;
      font-size: 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 5px;
        color: #1c80f3;
      }

      span {
        font-size: 12px;
        color: #1c80f3;
      }
    }
  }

  img {
    max-width: 200px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const TiposDeProduto = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  padding: 20px;

  div {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background: #f0f0f0;

    h1 {
      font-weight: 200;
      color: #0b1526;
      font-size: 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 5px;
        color: #1c80f3;
      }

      span {
        font-size: 12px;
        color: #1c80f3;
      }
    }

    &:hover {
      border: 1px solid #1c80f3;

      svg {
        color: #fff;
      }

      h1 {
        color: #fff;
        background-color: #1c80f3;
        border-radius: 30px;
      }
    }
  }

  img {
    max-width: 200px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
`

export const Next = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: right;
  margin-top: 20px;

  button {
    padding: 15px;
    border: 1px solid #1c80f3;
    background-color: #1c80f3;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
    
    svg {
      margin-left: 5px;
    }
    
    &:hover {
      opacity: 0.7;
    }
  }
`

export const StepTitle = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;

  p {
    color: #000;
    padding: 10px;
    font-size: 14px;
    opacity: 0.7;
    font-weight: 200;
  }

  h2 {
    display: flex;
    justify-content: left;
    font-size: 20px;
    color: #0b1526;
    font-weight: 200;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 0px;
    opacity: 0.9;
    max-width: 600px;

    svg {
      margin-right: 10px;
    }

    b {
      font-weight: 200;
      padding-left: 10px;
      color: #1c80f3 !important;
    }
  }
`

export const DivisaoCapsula = styled.div`
  max-width: 600px;
`

export const TitleCorpo = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 30px;

  img {
    max-width: 500px;
  }

  p {
    padding-left: 50px;
    text-align: left;
    font-weight: 200;
    font-size: 25px;
    
    span {
      font-size: 30px;
      font-weight: bold;
      background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
      text-decoration: none;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  @media (max-width: 957px) {
    display: block;
    
    img {
      max-width: 150px;
    }

    p {
      margin-top: 30px;
    }
  }

`

export const WhatsAppButton = styled.span`
  padding: 10px;
  background: linear-gradient(90deg, rgba(32,38,69,1) 0%, rgba(28,128,243,1) 100%);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.5s;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
  color: #ffff;
  cursor: pointer !important;
  margin-bottom: 20px;
  font-weight: bold;

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  &:hover {
    transition: 0.5s;
    background: linear-gradient(90deg, rgba(28,128,243,1) 0%, rgba(32,38,69,1) 100%);
  }

  @media (max-width: 757px) {
    /* right: 0;
    position: fixed;
    bottom: 0;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 7px;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px; */
  }
`

export const Sections4 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  
  div {
    padding-bottom: 50px;
    padding-left: 30px;
  }

  h2 {
    font-size: 35px;
    background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  ul {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(28, 128, 243, 1) 0%, rgba(32, 38, 69, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    list-style: none;

    li {
      color: black;
    }
  }

  a > span {
    margin-top: 30px;
    max-width: 300px;
  }

  p {
    font-size: 25px;
  }

  h2, p {
    text-align: left;
    color: black;
  }

  img {
    position: relative;
    max-width: 300px;
  }

  @media (max-width: 775px) {
    img {
      display: none;
    }

    div {
      padding-bottom: 0px !important;
      padding-left: 20px !important;
      padding: 20px;
    }
  }
`