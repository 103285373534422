import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll'
import { Wrapper, Footer, Content, Title, Sections, Sections2,
  TitleCorpo, WhatsAppButton, SectionsPanel, SectionPanelItems, 
  SectionPanelClient, SectionsNumeros, SectionDuasGrid, Sections3,
  SectionUmaGrid, SectionsDifDiv, Sections4, Sections5, Sections6,
  Sections7, SectionFormGroup, SectionCheckbox, AvisoUrgente,
  AvisoUrgenteMini
} from './styles'
import { FaFire, FaAngleDown, FaMoneyBillWave } from 'react-icons/fa'
import { FiDollarSign } from 'react-icons/fi'
import logoBranca from '../../assets/logo_branca.png';
import relogiofire from '../../assets/relogiofire.png';
import brasilanvisa from '../../assets/brasilanvisa.png';
import suamarcaaqui from '../../assets/suamarcaaqui.png';
import sections2 from '../../assets/sections2.png';
import money from '../../assets/money.png';
import tempodinheiro from '../../assets/tempodinheiro.png'
import anvisa from '../../assets/anvisa.png'
import tranquility from '../../assets/tranquility.png'
import imgdordecabeca from '../../assets/img-dor-de-cabeca.png'
import { motion } from "framer-motion"
import seloAnvisa from '../../assets/selo-anvisa.png'
import selomeg from '../../assets/certificacao-meg-3.png'

export function AfiliadosBrasil() {
  const [selecoesForm, setSelecoesForm] = useState('')
  const [infoVisible, setInfoVisible] = useState(true)
  const [data, setData] = useState({ 
    cnpj: false,
    product: 0,
    name: '',
    phone: '',
    instagram: '',
    email: '',
    city: '',
    nameProduct: '',
    descriptProduct: '',
    selecoesFormulario: '',
    problemProduct: ''
  })

  useEffect(() => {
    let hasProduct = ''

    if (data.product === 0) hasProduct = 'Não sei, preciso de ajuda.'
    if (data.product === 1) hasProduct = 'Tenho uma ideia, mas estou desenvolvendo.'
    if (data.product === 2) hasProduct = 'Já tenho tudo planejado.'

    let selecoesForm = "Marca Milionária\n\n" + "CNPJ: " + (data.cnpj ? 'Sim' : 'Não') + "\n" +
    "Nome: " + data.name + "\n" +
    "Telefone: " + data.phone + "\n" +
    "Qual o nome da sua Empresa: " + data.instagram + "\n" +
    "Email: " + data.email + "\n" +
    "Cidade: " + data.city + "\n" +
    "Maior problema hoje: " + data.problemProduct + "\n" +
    "Nome produto: " + data.nameProduct + "\n" +
    "Descrição produto: " + data.descriptProduct + "\n";


    setSelecoesForm(selecoesForm)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      {/* {infoVisible ? (
        <>
          <AvisoUrgente onClick={() => setInfoVisible(!infoVisible)}>
            <h2>Aproveite! Inscreva-se agora e ganhe uma bonificação!!</h2>
            <p>Você que se inscrever até <b>SÁBADO dia 25/05 ás 23:59</b> irá receber <b>20 produtos</b> A MAIS na criação do seu produto. <small>(Mínimo 200 unidades)</small></p>
          </AvisoUrgente>
        </>
      ) : (
        <>
          <AvisoUrgenteMini onClick={() => setInfoVisible(!infoVisible)}>
            <FaMoneyBillWave size={32} />
          </AvisoUrgenteMini>
        </>
      )}*/}
      <Wrapper>
        <Content>
          <Title>
            <h1>Bem-vindo à iCapsulas!</h1>
            <p>Fique tranquilo que não te trouxemos aqui para balela, então vamos <span>direto ao ponto.</span></p>
            <TitleCorpo>
              <div style={{ marginTop: 20, paddingTop: '56%', position: 'relative'}}><iframe src='https://player.vimeo.com/video/949310498?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' allow="autoplay; fullscreen; picture-in-picture" style={{position:'absolute',padding: 10,top:0,left:0,width:'100%',height:'100%', border: 0, borderRadius: 20}} title=""></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              <div style={{justifyContent: 'left'}}>
                <p>Somos uma empresa especialista na fabricação de encapsulados, suplementos solúveis e líquidos.<br />
                <span>TEMOS O MENOR PRAZO DE ENTREGA DO BRASIL!</span>
                </p>
              </div>
            </TitleCorpo>
            <TitleCorpo>
              <Link to='somosdiferentes' spy smooth offset={10} duration={800}><WhatsAppButton><FaFire size={16} /> QUERO SABER MAIS!</WhatsAppButton></Link>
              <img src={brasilanvisa} alt='Anvisa' />
            </TitleCorpo>
            <FaAngleDown className='pulse' size={70} color='#1c80f3' style={{marginTop: 10, fontWeight: 200}} />
          </Title>
          <Sections
              as={motion.section}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              className='somosdiferentes'>
              <img src={suamarcaaqui} alt='Sua marca aqui' />
              <SectionsDifDiv>
                <h2>Por que somos diferentes?</h2>
                <p>Acompanhamos você desde o início da ideia até o produto final na mão do seu cliente, cuidando de <b>cada detalhe.</b></p>
                <span>A sua única responsabilidade é a divulgação do seu produto e o resto você deixa com a gente.</span>

                <SectionsPanel>
                  <p>Papel da iCapsulas:</p>
                  
                  <SectionPanelItems>
                    <div>
                      <ul>
                        <li>1. CRIAÇÃO DO PRODUTO</li>
                        <li>2. REGISTRO INPI</li>
                        <li>3. VALIDAÇÃO</li>
                        <li>4. CRIAÇÃO DO DESIGN</li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>5. REGISTRO ANVISA</li>
                        <li>6. IMPRESSÃO GRÁFICA</li>
                        <li>7. PRODUÇÃO E ENVASE</li>
                        <li>8. TRANSPORTE E ENVIO</li>
                      </ul>
                    </div>
                  </SectionPanelItems>
                  <SectionPanelClient>
                    <p>Seu único papel:</p>
                    <SectionPanelItems>
                      <div>
                        <ul><li>1. DIVULGAR SEU PRODUTO</li></ul>
                      </div>
                    </SectionPanelItems>
                    <FaAngleDown className='pulse' size={70} color='#00b7ff' style={{marginTop: 10, fontWeight: 200}} />
                  </SectionPanelClient>
                </SectionsPanel>
              </SectionsDifDiv>
          </Sections>
          <Sections2>
            <div>
              <h2>Junte-se à iCapsulas, utilizamos matéria-prima qualificada, com fornecedores credenciados na IN/28</h2>
              <div>
                <img src={seloAnvisa} alt='Selo Anvisa' />
                <img src={selomeg} alt='Selo MEG3' />
              </div>
            </div>
            <img src={sections2} alt='Sections' />
          </Sections2>

          <Sections4>
            <div>
              <h2>Como funciona?</h2>
              <h2>Simples</h2>
              <p>Deixe seus dados abaixo. Nossa equipe entrará em contato para te apresentar ao Clube iCapsulas, onde você terá acesso à:</p>
              <ul>
                <li>• Prazos de entrega recordes</li>
                <li>• Suporte total na criação e design da sua marca</li>
                <li>• Produtos com qualidade garantida pela ANVISA.</li>
              </ul>
            </div>
          </Sections4>
          <Link to='preencherForm' spy smooth offset={10} duration={800} style={{ display: 'block', padding: 20 }}><WhatsAppButton><FaFire size={16} /> QUERO FAZER PARTE!</WhatsAppButton></Link>
          
          <Sections5>
            <div>
              <img src={tempodinheiro} alt='Tempo é dinheiro' />
              <h2>Tempo é dinheiro.</h2>
              <p>E a gente respeita os dois. Com os melhores prazos de entrega do Brasil, a gente garante que seu produto chegue voando, sem atrasos que dão dor de cabeça.</p>
            </div>
            <div>
              <img src={anvisa} alt='Anvisa' />
              <h2>Registrado e certificado pela ANVISA</h2>
              <p>Todos os nossos produtos são 100% regularizados pela Anvisa, produzidos em fábrica, ou seja, você tem total clareza de quem os fabrica.</p>
            </div>
            <div>
              <img src={tranquility} alt='Ainda com medo?' />
              <h2>Empresa consolidada no ramo de nutracêuticos</h2>
              <p></p>
            </div>
          </Sections5>
          {/* <Sections6 className='preencherForm'>
            <h4>Sem nenhuma dor de cabeça.</h4>
            <img src={imgdordecabeca} alt='Dor de Cabeça' />
          </Sections6> */}
          <Sections7 action="https://service.capsulecrm.com/service/newlead" accept-charset="UTF-8" method="post">
            <input type="hidden" name="FORM_ID" value="cd82bb13-d781-4b31-85ac-5802d9c45f3b" />
            <input type="hidden" name="COMPLETE_URL" value="https://icapsulas.com.br/obrigado" />
            <h2>Deixe seus dados, nossa equipe de especialista entrará em contato com você!</h2>
            <span>Responda ao formulário <small>(Menos de 3 min)</small></span>

            <SectionFormGroup>
              <h4>1. Qual o seu nome?</h4>
              <input type='text' name='FIRST_NAME' onChange={(e) => setData({ ...data, name: e.target.value })} />
            </SectionFormGroup>
            <SectionFormGroup>
              <h4>2. Qual o nome da sua Empresa?</h4>
              <input type='text' name='instagram' onChange={(e) => setData({ ...data, instagram: e.target.value })} />
            </SectionFormGroup>
            <SectionFormGroup>
              <h4>3. Qual seu e-mail?</h4>
              <input type='text' name='EMAIL' onChange={(e) => setData({ ...data, email: e.target.value })} />
            </SectionFormGroup>
            <SectionFormGroup>
              <h4>4. Qual seu telefone?</h4>
              <input type='text' name='PHONE' onChange={(e) => setData({ ...data, phone: e.target.value })} />
            </SectionFormGroup>
            <SectionFormGroup>
              <h4>5. De onde você é?</h4>
              <input type='text' name='city' onChange={(e) => setData({ ...data, city: e.target.value })} />
            </SectionFormGroup>
            <SectionFormGroup  style={{textAlign: 'left'}}>
              <h4>6. Qual o maior problema que você enfrenta com seus fornecedores autais?</h4>
              <input type='text' name='problemProduct' onChange={(e) => setData({ ...data, problemProduct: e.target.value })} />
              <h5>Qual é o nome de um de seus produtos?</h5>
              <input type='text' name='nameProduct' onChange={(e) => setData({ ...data, nameProduct: e.target.value })} />\

              <button type="submit"><FaFire size={16} /> ENVIAR DADOS!</button>
              {/* <a style={{ display: 'block', padding: 20 }} href='#'rel="noopener noreferrer"><WhatsAppButton><FaFire size={16} /> ENVIAR DADOS!</WhatsAppButton></a> */}
            </SectionFormGroup>
            <input type="hidden" name="NOTE" value={selecoesForm} />
          </Sections7>
        </Content>
        <Footer>
          <img src={logoBranca} alt='iCapsulas' />
          <p>Copyright © {new Date().getFullYear()} iCapsulas. Todos direitos reservados.</p>
        </Footer>
      </Wrapper>
    </>
  )
}
