import React from 'react';
import { GlobalStyle } from './styles/global';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { Header } from './components/Header';
import { Header2 } from './components/Header2';
import { Contact } from './components/Contact';
import { Main } from './components/Main';
import { Products } from './components/Products';
import { AfiliadosBrasil } from './components/AfiliadosBrasil';
import { AfiliadosBrasilThanks } from './components/AfiliadosBrasilThanks';
import { LoadingPage } from './components/LoadingPage';
// import {ToastContainer} from 'react-toastify'

import history from './history';
import { AuthContext } from './contexts/auth';

function CustomRoute({ isPrivate, isPrivateCustomer, ...rest } : any) {
  const { auth, loadingLogin } = React.useContext(AuthContext)

  if (loadingLogin) {
    return <LoadingPage />
  }

  if (isPrivate && !auth.authenticated) {
    return <Redirect to='/' />
  }

  if (isPrivate && !auth.admin) {
    return <Redirect to='/' />
  }
  
  if (isPrivateCustomer && !auth.authenticated) {
    return <Redirect to='/admin/login' />
  }

  return <Route { ...rest } />
}

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <CustomRoute exact path='/'>
            <Header />
            <Main />
          </CustomRoute>
          <CustomRoute exact path='/orcamento-enviado'>
            <Header />
            <Contact />
          </CustomRoute>
          <CustomRoute exact path='/orcamento'>
            <Header />
            <Products />
          </CustomRoute>
          <CustomRoute path='/marcamilionaria'>
            <Header2 />
            <AfiliadosBrasil />
          </CustomRoute>
          <CustomRoute path='/obrigado'>
            <Header2 />
            <AfiliadosBrasilThanks />
          </CustomRoute>
        </Switch>
        <GlobalStyle />
      </Router>
    </>
  );
}

export default App;
