import React, { useState } from 'react';

export const ActiveMenuContext = React.createContext<any>('');
 
export const ActiveMenuProvider: any = (props: any) => {
  const [activeMenu, setActiveMenu] = useState('')

  return (
    <ActiveMenuContext.Provider value={{ activeMenu, setActiveMenu }}>
      {props.children}
    </ActiveMenuContext.Provider>
  )
}
