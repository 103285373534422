import styled, { keyframes } from 'styled-components'
import fundoSetores from '../../assets/fundo-setores.png';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ModalSpan = styled.span`
  h3 {
    padding: 15px;
  }
`

export const ModalContent = styled.div`
  position: absolute;
`

export const Wrapper = styled.div`
  display: block;
  /* margin-top: 65px;   */
  max-width: 100%;
  background-color: #ffff;

  img {
    max-width: 100%;
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .react-slideshow-container .default-nav {
    background: transparent;
    opacity: .6;
  }
`

export const About = styled.div`
  display: block;
  background-color: #ffff;
  max-width: 800px;
  color: #0b1526;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
  align-items: center;

  @media (max-width: 612px) {
    h2 {
      font-size: 20px !important;
    }

    span {
      font-size: 15px !important;
    }
  }

  h2 {
    font-size: 35px;
    font-weight: 200;
    color: #0b1526;

    span {
      font-weight: 200;
    }
  }

  p {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-weight: 200;
    line-height: 1.80;
    opacity: 0.4;

    span {
      font-size: 16px;
      color: #0086ef !important;
      font-weight: 200;
    }
  }

  span {
    padding: 10px;
    font-size: 30px;
    background: #0b1526;
    color: #ffff;
    border-radius: 40px;
    font-weight: 200;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 5px 12px #a7a7a7;
    margin-top: -30px;
    transition: 0.5s;

    svg {
      margin-left: 10px;
      margin-right: 10px;
      transition: transform 0.5s, opacity 0.5s;
    }


    &:hover {
      box-shadow: 4px 5px 12px #2d2d2d;
      transition: 0.5s;
      color: #1c80f3;

      svg:first-child {
        transform: translateX(-100%);
        transition: transform 0.5s, opacity 0.5s;
      }
      svg:last-child {
        transform: translateX(100%);
        transition: transform 0.5s, opacity 0.5s;
      }
    }
  }
`

export const SectionBanner = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  margin-top: 30px;

  img {
    display: flex;
    width: 100%;
  }
`

export const Potes = styled.div`
  display: flex;
  position: absolute;

  img {
    display: flex;
    width: 100%;
    transition-delay: 250ms;
    -webkit-transition: left 4s;
    -moz-transition: left 4s;
    -ms-transition: left 4s;
    -o-transition: left 4s;
    transition: left 4s;
    transition-timing-function: linear;
  }

  @media (min-width: 2807px) {
    img {
      margin-left: 30%;
    }
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #202645;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  background: #ffff;

  div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      color: #ffffff;
      opacity: .5;
      transition: 0.2s;
      
      &:hover {
        opacity: .9;
        transition: 0.2s;
      }
    }
  }

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }
`

export const CompleteLine = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 30px;
  padding: 30px;
  transition: 0.2s;

  a {
    font-size: 20px;
    font-weight: bold;
    color: #0086ef;
  }

  h2 {
    font-weight: bold;
    font-size: 25px;
    color: #0086ef;
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: 0.2s;
    opacity: .8;
  }

  @media (max-width: 578px) {
    margin-top: -35px;

    h2 {
      font-size: 15px;
    }
  }
`

export const PromoContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;

  img {
    border-radius: 5px;
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const IsoContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 50px;
  padding: 20px;
  
  div {
    min-height: 220px;
    min-width: 220px;
    background: #0b1526;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    padding-top: 15%;
    background-size: contain;
    cursor: pointer;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    
    &:hover {
      transition: 0.3s;
      box-shadow: 4px 5px 12px #a2a2a2;

      p, h3, svg {
        transition: 0.2s;
        color: #1c80f3;
      }
    }

    p, h3, svg {
      cursor: pointer;
      transition: 0.2s;
      color: #fff;
    }

    p {
      opacity: 0.5;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 739px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);

    img {
      max-width: 90px;
      max-height: 90px;
    }
  }

  @media (max-width: 504px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Club = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media (max-width: 754px) {
    grid-template-columns: repeat(1, 1fr);
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Subscribe = styled.div`
  width: 100%;
  display: block;
  justify-content: center;
  padding-right: 10px;
  background: rgb(39, 39, 39, .3);
  border-radius: 0px 15px 15px 0px;
  padding: 30px;

  h3 {
    color: #0086ef;
    display: block;
    width: 100%;
    font-size: 25px;
    text-align: right;
    text-shadow: 0 0 4px rgb(0, 134, 239, 0.6);
    padding-right: 10px;
  }
  
  p {
    color: #ffffff;
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 10px;
  }

  input {
    margin-top: 10px;
    max-width: 400px;
  }

  @media (max-width: 754px) {
    border-radius: 15px 15px 15px 15px;

    h3, p {
      text-align: center;
    }
  }
`

export const VerifyProduct = styled.div`
  width: 100%;
  display: block;
  justify-content: center;
  padding-left: 10px;
  min-height: 346px;
  background: #272727;
  border-radius: 15px 0px 0px 15px;
  padding: 30px;
  background: url(${fundoSetores});
  background-size: contain;

  h3 {
    color: #0086ef;
    display: flex;
    width: 100%;
    font-size: 25px;
    text-shadow: 0 0 4px rgb(0, 134, 239, 0.6);
    margin-top: -20px;
  }

  p {
    display: block;
    width: 100%;
    color: #ffffff;
    padding-top: 6px;
  }

  img {
    max-width: 200px;
    margin-left: -30px;
  }

  button {
    max-width: 150px;
    font-size: 12px;
    padding: 4px;
    margin-top: 5px;
  }

  span {
    color: #ffffff;
    background: rgba(0, 134, 239, .3);
    font-size: 12px;
    padding: 5px;
    border-radius: 2px 10px 2px 10px;
    display: flex;
    max-width: 225px;
    margin-top: 10px;
    
    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 508px) {
    span {
      font-size: 10px;
      max-width: 130px;
      text-align: center;
    }
  }

  @media (max-width: 754px) {
    border-radius: 15px 15px 15px 15px;

    h3 {
      font-size: 20px;
    }
  }

  @media (max-width: 450px) {
    img {
      max-width: 150px;
    }
  }

  @media (max-width: 354px) {
    img {
      max-width: 110px;
    }
  }
`

export const FormContent = styled.div`
  width: 100%;
  display: block;
  justify-content: right;

  span, h4 {
    display: block;
    max-width: 400px;
    background: #ff5c5c;
    color: #ffffff;
    font-size: 14px;
    padding: 5px;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 20px 20px 3px 20px;
    text-align: center;
    margin-top: 10px;
  }

  h4 {
    background: rgb(4, 211, 97);
    color: #2b2b2c;
  }

  @media (max-width: 754px) {
    justify-content: center;
    max-width: 557px;
  }
`

export const SendButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 10px;

  button {
    color: #ffffff;
    background: #0086ef;
    padding: 10px;
    border-radius: 50px 50px 10px 50px;
    font-size: 14px;
    margin-top: 20px;
    transition: 0.2s;
    display: block;
    justify-content: right;
    width: 200px;
    margin-top: 20px;
    max-height: 45px;
    border: 3px solid #0086ef;
    transition: 0.2s;
    box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
    
    &:hover {
      border-radius: 10px 50px 50px 50px;
      transition: 0.2s;
      box-shadow: 0px 8px 65px -6px #0086ef;
    }
  }
`

interface ButtonElementProps {
  readonly loading: boolean;
};

export const ButtonElement = styled.button.attrs((props: any) => ({
  type: 'button',
  disabled: props.loading ? true : false,
}))<ButtonElementProps>`
  color: #ffffff;
  background: #0086ef;
  padding: 10px;
  border-radius: 50px 50px 10px 50px;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.2s;
  display: block;
  justify-content: right;
  width: 200px;
  margin-top: 20px;
  max-height: 45px;
  border: 3px solid #0086ef;
  transition: 0.2s;
  box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);

  opacity: ${(props: any) => props.loading ? .5 : 1};
  cursor: ${(props: any) => props.loading ? 'not-allowed' : 'pointer'};
  
  &:hover {
    border-radius: 10px 50px 50px 50px;
    transition: 0.2s;
    box-shadow: 0px 8px 65px -6px #0086ef;
  }

  svg {
    animation: ${rotate} 1s linear infinite;
  }
`

export const ProductsImage = styled.img`
  width: 100%;
  margin-top: -55px;
  margin-bottom: 15px;
`

export const SectionAnuncio = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;

  h1 {
    display: flex;  
  }
`

export const PotesBg = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
  justify-content: center;

  @media (max-width: 500px) {
    img {
      max-width: 100% !important;
    }
  }

  img {
    max-width: 70%;
    transition: 0.5s;
    
    &:hover {
      transition: 0.5s;
      max-width: 75%;
    }
  }
`

export const AboutUs = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 30px;

  h1 {
    font-size: 35px;
    font-weight: 200;
    color: rgb(11, 21, 38);
  }

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: 10px;
    text-align: justify;
    opacity: 0.6;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 26px;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 976px) {
    grid-template-columns: repeat(1, 1fr);
  }

`

export const AboutUsImg = styled.div`
  max-width: 1200px;
  max-width: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;

  img {
    border-radius: 30px;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: .8;
    }
  }
`

export const ContactInfo = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 50px;
  padding: 20px;

  div {
    p {
      font-size: 17px;
      opacity: 0.6;
      display: flex;
      align-items: center;
      
      svg {
        margin-right: 5px;
      }
    }

    a {
      text-decoration: none;
      color: rgb(11, 21, 38);
      transition: 0.5s;
      opacity: 0.6;
      cursor: pointer;

      &:hover {
        opacity: 0.3;
        transition: 0.5s;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 200;
      color: rgb(11, 21, 38);
      margin-bottom: 15px;
    }
  }

  @media (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const FormLink = styled.div`
  width: 100%;
  display: flex;
  background:  #f5f5f5;
  height: 200px;
  margin-top: 50px;

  div {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    text-align: center;

    a {
      padding: 15px;
      background: #ffff;
      color: rgba(32,38,69,1);
      padding-left: 20px;
      padding-right: 20px;
      text-decoration: none;
      cursor: pointer;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 600;
      align-items: center;
      display: flex;
      transition: 0.3s;

      svg {
        margin-left: 15px;
      }

      &:hover {
        transition: 0.3s;
        background: rgba(32,38,69,1);
        color: #ffff;
        box-shadow: 2px 2px 15px #f5f5f5;
      }
    }
  }

  @media (max-width: 550px) {
    a {
      font-size: 15px !important;
    }
  }
`