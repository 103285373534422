export default function translateApiErrors(code: string) {
  switch(code) {
    case 'USER-01':
      return 'Usuário não encontrado.'
    case 'USER-03':
      return 'Usuário não aprovado. Aguarde aprovação.'
    case 'PWD-01':
      return 'Senha ou Usuário incorreto(s).'
    case 'BODY-01':
      return 'Preencha os campos para entrar.'
    default:
      return 'Erro na api. Tente novamente.'
  }
};
