import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;

  img {
    margin-top: 50px;
    max-width: 200px;
    opacity: .6;
  }
`
