import React from 'react';
import { Wrapper} from './styles'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export function Loading({ color = '#ffff' }:{ color?: string}) {
  return (
    <>
      <Wrapper>
        <AiOutlineLoading3Quarters color={color} size={30} />      
      </Wrapper>
    </>
  )
}
