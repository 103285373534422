import React, { useState } from 'react';

export const ComissionsContext = React.createContext<any>('');
 
export const ComissionsProvider: any = (props: any) => {
  const [comissions, setComissions] = useState([])

  return (
    <ComissionsContext.Provider value={{ comissions, setComissions }}>
      {props.children}
    </ComissionsContext.Provider>
  )
}
