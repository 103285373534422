import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 100px;

  h5 {
    svg {
      opacity: .8;
      transform: scale(1);
      animation: float 1s infinite;
  
      @keyframes float {
        0% {
          padding-top: 10px;
        }
  
        50% {
          padding-top: 2px;
        }
  
        100% {
          padding-top: 0px;
        }
      }
    }
  }

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #202645;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  background: #ffff;

  div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      color: #ffffff;
      opacity: .5;
      transition: 0.2s;
      
      &:hover {
        opacity: .9;
        transition: 0.2s;
      }
    }
  }

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }
`

export const Content = styled.div`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const Title = styled.div`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;

  h1 {
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 200;
    color: #0b1526;
    margin-bottom: 15px;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  p {
    color: #0b1526;
    opacity: 0.5;
  }
`

export const Sections = styled.section`
  display: block;
  max-width: 75rem;
  margin: 0 auto;
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
`
export const StepsOverview = styled.ul`
  max-width: 1200px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;

  li {
    display: inline;
    margin: 0 0 0 15px; 
    list-style: none;
    color: #c7c7c7;
    font-size: 15px;
    padding: 10px;
    cursor: pointer;

    span {
      color: #1c80f3;
      border-radius: 3px;
      display: flex;
      align-items: center;

      b {
        display: flex;
        align-items: center;
        color: #6e8b00;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar {
    width: 1px !important;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
`
export const Step01 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

export const Step02 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step03 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step04 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step05 = styled.div``
export const Step06 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h1 {
    small {
      font-size: 14px;
      opacity: 0.6;
      margin-left: 5px;
    }
  }

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`
export const Step07 = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  border: 1px solid rgb(212, 218, 222);
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-bottom: 20px;

  h1 {
    small {
      font-size: 14px;
      opacity: 0.6;
      margin-left: 5px;
    }
  }

  h5 {
    color: #0b1526;
    font-weight: 200;
  }

  h2 {
    color: #0b1526;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 15px;
    padding: 5px;
    
    svg {
      margin-right: 10px;
    }
  }

  h4 {
    display: flex;
    max-width: 1200px;
    color: #0b1526;
    padding: 20px;
    font-weight: 200;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    opacity: 0.7;

    svg {
      margin-right: 10px;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

export const FormualarioEnvio = styled.div`
  display: block;

  form {
    padding: 10px;
    margin-top: 20px;
    color: #000;
    display: block;

    div {
      padding: 10px;
      text-align: start;

      input {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px;
        border: 1px solid #b2b2b2;
        border-radius: 5px;
        background: transparent;
      }
    }
  }
`

export const FormSubmit = styled.div`
  padding: 10px !important;
  display: flex;
  width: 100%;

  input {
    margin-right: 15px;
    background: #1d80f3 !important;
    color: #fff;
    border: 1px solid #1d80f3 !important;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      opacity: 0.8;
    }
  }
`

export const TipoCapsula = styled.div`
  max-width: 1200px;
  display: inline-flex;
  margin: 0 auto;
  padding: 20px;
  transition: 0.3s;

  @media (max-width: 725px) {
    display: block !important;
  }

  div {
    padding: 10px;

    span {
      display: inline-flex;
      border-radius: 5px;
      padding: 10px;
    }

    #active {
      border: 2px solid #1c80f3 !important;
    }

    svg {
      cursor: pointer;
      margin-left: 10px;
      border: 2px solid #fff;
      border-radius: 15px;
      box-shadow: 2px 2px 5px #aaa;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  img {
    max-width: 300px;
    transition: 0.3s;
  }
`

export const TipoContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 20px;

  @media (max-width: 750px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (max-width: 619px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  &:last-child {
    border-bottom: 0px;
  }

  h1 {
    font-size: 16px !important;
  }

  div {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    cursor: pointer;
    background: #f0f0f0;
    padding: 10px;
    transition: 0.2s;

    p {
      color: #919090;
      font-size: 10px;
    }

    &:hover {
      transition: 0.2s;
      border: 1px solid #1c80f3;
      background: #1c80f3;

      p {
        color: #f5f5f5;
        font-size: 10px;
        opacity: 0.8;
      }
      
      h1 {
        color: #fff;
      }

      svg {
        color: #fff;
      }
    }

    svg {
      color: #0b1526;
    }
  }
`

export const TiposDeFormulas = styled.div`
  max-width: 1200px;
  display: block;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;

  h2 {
    display: flex;
    max-width: 1200px;
    text-align: left;
    justify-content: start;
    font-weight: 200;
    color: #0b1526;
    font-size: 16px;
    opacity: 0.8;

    small {
      color: #1c80f3;
      margin-left: 10px;
      text-decoration: underline;
    }
  }

  div {
    h1 {
      font-weight: 200;
      color: #0b1526;
      font-size: 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 5px;
        color: #1c80f3;
      }

      span {
        font-size: 12px;
        color: #1c80f3;
      }
    }
  }

  img {
    max-width: 200px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const TiposDeProduto = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  padding: 20px;

  div {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background: #f0f0f0;

    h1 {
      font-weight: 200;
      color: #0b1526;
      font-size: 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 5px;
        color: #1c80f3;
      }

      span {
        font-size: 12px;
        color: #1c80f3;
      }
    }

    &:hover {
      border: 1px solid #1c80f3;

      svg {
        color: #fff;
      }

      h1 {
        color: #fff;
        background-color: #1c80f3;
        border-radius: 30px;
      }
    }
  }

  img {
    max-width: 200px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
`

export const Next = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: right;
  margin-top: 20px;

  button {
    padding: 15px;
    border: 1px solid #1c80f3;
    background-color: #1c80f3;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
    
    svg {
      margin-left: 5px;
    }
    
    &:hover {
      opacity: 0.7;
    }
  }
`

export const StepTitle = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;

  p {
    color: #000;
    padding: 10px;
    font-size: 14px;
    opacity: 0.7;
    font-weight: 200;
  }

  h2 {
    display: flex;
    justify-content: left;
    font-size: 20px;
    color: #0b1526;
    font-weight: 200;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 0px;
    opacity: 0.9;
    max-width: 600px;

    svg {
      margin-right: 10px;
    }

    b {
      font-weight: 200;
      padding-left: 10px;
      color: #1c80f3 !important;
    }
  }
`

export const DivisaoCapsula = styled.div`
  max-width: 600px;
`