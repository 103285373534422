import React from 'react'
import { TbHandClick } from "react-icons/tb"
import {
  FiZap, FiDivide, FiAtSign, FiPenTool, FiCheckCircle, FiMail, FiPhone, FiInstagram,
  FiTruck, FiImage, FiFilter, FiChevronsRight, FiChevronsLeft } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { GiBottledBolt } from 'react-icons/gi'
import { 
  Wrapper, About, Footer, IsoContent, Potes, PotesBg,
  AboutUs, SectionBanner, AboutUsImg, ContactInfo, FormLink } from "./styles";

import { useHistory } from 'react-router-dom'
import 'react-slideshow-image/dist/styles.css';
import slide01 from '../../assets/fundo02.png';
import potes from '../../assets/potes01.png';
import textos from '../../assets/texto.png';
import potesBg from '../../assets/background-potes.png';
import fotoFachada2 from '../../assets/foto-empresa-02.png';
import fotoEstoque from '../../assets/estoque.png';
import fotoEmpresa from '../../assets/empresa02.png';
import fotoFachada003 from '../../assets/facha003.png';
import logo2 from '../../assets/footer-logo.png';

export function Main() {
  const history = useHistory();

  function handleGoToOrcamento() {
    history.push('/orcamento')
  }

  return (
    <>
    <Wrapper>
      <SectionBanner>
        <Potes>
          <img src={textos} alt='Textos' />
        </Potes>
        <Potes>
          <img src={potes} alt='Potes' />
        </Potes>
        <img src={slide01} alt='iCapsulas' />
      </SectionBanner>
      <About>
        <div style={{ paddingTop: '56%', position: 'relative'}}><iframe src='https://player.vimeo.com/video/898179479?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' allow="autoplay; fullscreen; picture-in-picture" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%', border: 0, borderRadius: 10}} title="Apresentação - iCapsulas"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </About>
      <About id="servicos">
        <h2>COMO TER SEU PROJETO COMPLETO</h2>
        <p>Nossa equipe cuida de tudo para você, a escolha do seu produto, design exclusivo, produção e entrega de todo projeto.</p>
      </About>
      <IsoContent>
        <div>
          <FiZap size={40} />
          <h3>Escolha dos produtos</h3>
          <p>Fórmulas exclusivas</p>
        </div>
        <div>
          <FiDivide size={40} />
          <h3>Orçamento do Projeto</h3>
          <p>Valores e formas de pagamento facilitadas</p>
        </div>
        <div>
          <FiAtSign size={40} />
          <h3>Consulta de Marca</h3>
          <p>Consulta simples de nomes no INPI</p>
        </div>
        <div>
          <FiPenTool size={40} />
          <h3>Criação do Design</h3>
          <p>Design de rótulo e caixa do produto</p>
        </div>
        <div>
          <FiCheckCircle size={40} />
          <h3>Registros da Anvisa</h3>
          <p>100% regularizados</p>
        </div>
        <div>
          <FiImage size={40} />
          <h3>Impressão Gráfica</h3>
          <p>Máxima qualidade de impressão</p>
        </div>
        <div>
          <FiFilter size={40} />
          <h3>Produção e Envase</h3>
          <p>Matérias primas 100% aprovadas</p>
        </div>
        <div>
          <FiTruck size={40} />
          <h3>Transporte e Envio</h3>
          <p>Entregamos em seu endereço</p>
        </div>
      </IsoContent>
      <About>
        <span onClick={handleGoToOrcamento}><FiChevronsLeft size={30} /> QUERO CRIAR MEU PRODUTO <GiBottledBolt size={30} /> <FiChevronsRight size={30} /></span>
      </About>
      <PotesBg>
        <img src={potesBg} alt='Potes' />
      </PotesBg>
      <AboutUs id="sobre">
        <div>
          <h1>Quem somos?</h1>
          <p>A iCapsulas é uma indústria de nutracêuticos especializada em fabricação e terceirização de vitaminas e suplementos alimentares.</p>
          <p>Cuidamos de todo o processo de criação de marca e fabricação de produtos com entrega em todo o Brasil.</p>
          <p>Nossas matérias primas são as mais puras e todas aprovadas pela Anvisa, oferecendo a máxima qualidade para sua linha de produtos, trazendo maior retorno financeiro para sua marca.</p>
          <p>Construa seu sucesso conosco e conquiste clientes trazendo mais saúde e qualidade de vida todos os dias.</p>
        </div>
        <AboutUsImg>
          <img src={fotoFachada003} alt='Empresa' />
          <img src={fotoFachada2} alt='Empresa' />
          <img src={fotoEstoque} alt='Empresa' />
          <img src={fotoEmpresa} alt='Empresa' />
        </AboutUsImg>
      </AboutUs>
      <FormLink>
        <div>
          <a href='#' onClick={handleGoToOrcamento}>PREENCHA SEUS DADOS AQUI E ENTRAREMOS EM CONTATO <TbHandClick size={25} /></a>
        </div>
      </FormLink>
      <ContactInfo id="contato">
        <div>
          <h3>Empresa</h3>
          <p>I-CAPSULAS INDUSTRIA DE SUPLEMENTOS ALIMENTARES LTDA</p>
          <p>CNPJ: 49.803.409/0001-35</p>
          <p>IE: 010/0207308</p>
        </div>
        <div>
          <h3>Suporte</h3>
          <p><FiMail size={17} /> contato@icapsulas.com.br</p>
          <p><FaWhatsapp size={17} /> (54) 3698-1209 \ (54) 3698-1229</p>
        </div>
        <div>
          <h3>Redes Sociais</h3>
          <a href='https://instagram.com/icapsulasbr' target='_blank'><FiInstagram size={28} /></a>
        </div>
        <div>
          <h3>Como chegar</h3>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3484.7174766736825!2d-51.52139382459118!3d-29.143522490086074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c3b2401319a35%3A0x97e6a5f8ffdbffa1!2siCapsulas!5e0!3m2!1spt-BR!2sbr!4v1697901223026!5m2!1spt-BR!2sbr" width="50%" height="150" style={{ border:0, borderRadius: '30px' }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        </div>
      </ContactInfo>
      <Footer>
        <div>
          <p>Copyright © {new Date().getFullYear()} iCapsulas. Todos os direitos reservados.</p>
        </div>
        <div>
          <img src={logo2} alt='iCapsulas' />
        </div>
      </Footer>
    </Wrapper>
    </>
  )
}