import React, { useState } from 'react';

export const LanguagesContext = React.createContext<any>('pt');
 
export const LanguagesProvider: any = (props: any) => {
  const [language, setLanguage] = useState('pt')

  return (
    <LanguagesContext.Provider value={{ language, setLanguage }}>
      {props.children}
    </LanguagesContext.Provider>
  )
}
