/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import history from '../history';
import translateApiErrors from '../utils/translateApiErrors'
import { toast } from 'react-toastify'

export const AuthContext = React.createContext<any>({name: '', token: '', authenticated: false});
 
export const AuthProvider: any = (props: any) => {
  const [auth, setAuth] = useState({
    name: '',
    uid: '',
    token: '',
    authenticated: false,
    admin: false,
    stock: false,
    error: false,
    errorMessage: ''
  })
  const [loadingLogin, setLoadingLogin] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('')
    const userId: any = localStorage.getItem('icapsulas_user_id')
    const userName = localStorage.getItem('icapsulas_user_name') ?? ''
    const admin = localStorage.getItem('icapsulas_user_admin') === 'true' ? true : false
    const stock = localStorage.getItem('icapsulas_user_stock') === 'true' ? true : false

    if (token) {
      setAuth({ 
        ...auth,
        authenticated: true,
        uid: JSON.parse(userId),
        token: JSON.parse(token),
        name: userName,
        admin: admin,
        stock: stock
      })
    }

    setLoadingLogin(false)
  }, [])

  async function handleLogin(body: any) {
    try {
      if (!body.email || !body.password) {
        setAuth({
          ...auth,
          error: true,
          errorMessage: translateApiErrors('BODY-01')
        })

        return
      }

      setLoadingLogin(true)
  
      const response = await api.post('/login', body);
  
      localStorage.setItem('', JSON.stringify(response.data.token))
      localStorage.setItem('icapsulas_user_name', JSON.stringify(response.data.user.name))
      localStorage.setItem('icapsulas_user_admin', JSON.stringify(response.data.user.admin))
      localStorage.setItem('icapsulas_user_id', JSON.stringify(response.data.user.id))
      localStorage.setItem('icapsulas_user_stock', JSON.stringify(response.data.user.is_stock))
  
      setAuth({
        name: response.data.user.name,
        uid: response.data.user.id,
        authenticated: true,
        token: response.data.token,
        admin: response.data.user.admin,
        stock: response.data.user.is_stock,
        error: false,
        errorMessage: ''
      })
  
      setLoadingLogin(false)
      
      if (response.data.user.admin) history.push('/dashboard')
      else if(response.data.user.is_stock) history.push('/shop/my-dash')
      else history.push('/shop/my-dash')
    } catch (err: any) {
      const code = err.response.data.code ? err.response.data.code : ''
      toast.error("Erro ao realizar login!", {
        position: toast.POSITION.TOP_RIGHT
      });
      setAuth({
        ...auth,
        error: true,
        errorMessage: translateApiErrors(code)
      })

      setLoadingLogin(false)
    }
  }

  async function handleLogout() {
    localStorage.removeItem('icapsulas_token');
    localStorage.removeItem('icapsulas_user_name');
    localStorage.removeItem('icapsulas_user_admin');
    localStorage.removeItem('icapsulas_user_id');
    localStorage.removeItem('icapsulas_user_stock');

    setAuth({
      name: '',
      uid: '',
      authenticated: false,
      token: '',
      admin: false,
      stock: false,
      error: false,
      errorMessage: ''
    })

    history.push('/admin/login')
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, handleLogin, handleLogout, loadingLogin, setLoadingLogin }}>
      {props.children}
    </AuthContext.Provider>
  )
}
