import React, { useEffect, useState } from 'react';
import { Wrapper, Footer, Content, Title, Sections, Sections2,
  TitleCorpo, WhatsAppButton, SectionsPanel, SectionPanelItems, 
  SectionPanelClient, SectionsNumeros, SectionDuasGrid, Sections3,
  SectionUmaGrid, SectionsDifDiv, Sections4, Sections5, Sections6,
  Sections7, SectionFormGroup, SectionCheckbox
} from './styles'
import { IoRocketOutline } from 'react-icons/io5'
import { BiCapsule } from 'react-icons/bi'
import { FcOk } from 'react-icons/fc'
import { SiAtom } from 'react-icons/si'
import { FaFire, FaAngleDown, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'
import { CgEditContrast } from 'react-icons/cg'
import logo2 from '../../assets/footer-logo.png';
import logoBranca from '../../assets/logo_branca.png';
import relogiofire from '../../assets/relogiofire.png';
import brasilanvisa from '../../assets/brasilanvisa.png';
import suamarcaaqui from '../../assets/suamarcaaqui.png';
import sections2 from '../../assets/sections2.png';
import dinheiroMoedas from '../../assets/dinheiro-moedas.png';
import dinheiroMoedas2 from '../../assets/dinheiro-moedas2.png';
import money from '../../assets/money.png';
import tempodinheiro from '../../assets/tempodinheiro.png'
import anvisa from '../../assets/anvisa.png'
import tranquility from '../../assets/tranquility.png'
import imgdordecabeca from '../../assets/img-dor-de-cabeca.png'

import { FormGroup } from '../Contact/styles';

export function AfiliadosBrasilThanks() {
  const [selecoesForm, setSelecoesForm] = useState('')

  return (
    <>
      <Wrapper>
        <Content>
          <h2>O seu sucesso começa agora e não depende de mais ninguém.</h2>
          <p>Estamos analisando suas informações e, em breve, um dos nossos especialistas entrará em contato para dar sequência ao seu projeto.</p>
          
          <p>Mas, enquanto isso... por que esperar?</p>
          <p>O que acha de montarmos um produto juntos?</p>

          <span><FaAngleDoubleRight size={20} /> É GRATUITO E SEM COMPROMISSO <FaAngleDoubleLeft size={20} /></span>

          <a style={{ display: 'block', padding: 20 }} href='/orcamento'rel="noopener noreferrer"><WhatsAppButton><FaFire size={16} /> QUERO CRIAR MEU PRODUTO!</WhatsAppButton></a>
        </Content>
        <Footer>
          <img src={logoBranca} alt='iCapsulas' />
          <p>Copyright © {new Date().getFullYear()} iCapsulas. Todos direitos reservados.</p>
        </Footer>
      </Wrapper>
    </>
  )
}
